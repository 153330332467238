<template>
  <div class="card-notice"  @click="goToNoticia">
    <div
      class="card-img-top"
      :style="{
        'background-image': 'url(' + require(`@/assets/${imagen}`) + ')',
      }"
    ></div>
    <div class="card-body-template">
      <h5 class="card-title-template">{{ titulo }}</h5>
      <p class="card-text-template">
        <span class="badge rounded-pill text-bg-secondary mb-2">Publicado: {{ fecha }}</span>
      </p>

      <!-- <div class="card-footer text-muted">
        <router-link
          :to="{ name: 'Noticia', params: { id: id_publicacion } }"
          class="menu-link"
          > Clic AQUÍ</router-link
        >
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Noticias",

  props: {
    imagen: String,
    titulo: String,
    fecha: String,
    id_publicacion: Number,
  },

  methods: {
    goToNoticia() {
      this.$router.push({ name: 'Noticia', params: { id: this.id_publicacion } });
    }
  }
};
</script>

<style scope>
.card-notice {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: space-between;

  border-radius: 10px;
  color: var(--green-blnv);
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}

.card-body-template .card-title-template{
  color: var(--green-blnv);
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Comic Neue", cursive;
  font-weight: bold;
  font-size: 1rem;
}

.card-body-template .card-title-template .card-text-template .publicated-span{
  color: var(--green-blnv) !important;
}

/* .flotante-img {
  position: absolute;
  transform: translateY(100px) translateX(100px);
  font-size: 2rem;
} */

.card-notice:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: 0.4s;
}

.card-img-top {
  /* gap: 10px;
  width: 350px;
  height: 350px;
  border: 5px solid red;
  margin-left: 0.5rem; */

  /* border: blue solid 2px; */
  min-height: 250px;

  border-radius: 10px;
  font-weight: bold;
  /* padding: 10px; */

  justify-content: center;

  background-size: cover;
  background-position: center center;

  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px; */
}

.menu-link-img {
  color: var(--green-blnv);
  text-decoration: underline;
  display: none;
}

.menu-link {
  color: white;
  text-decoration: none;
}

.card-notice .card-body .menu-link:active,
.card-notice .card-body .menu-link:hover {
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  text-decoration: underline;
  display: block;
}

.card-body {
  margin-left: 0.5rem;
  color: #004d27;
}

.card-title {
  color: #fff;
  font-size: 1rem;
}

.titulo p {
  color: #004d27;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}


@media screen and (max-width: 992px) {
  /* .flotante-img {
    transform: translateY(100px) translateX(50px);
    font-size: 2rem;
  } */
}

@media screen and (max-width: 768px) {
  /* .flotante-img {
    transform: translateY(100px) translateX(60px);
    font-size: 2rem;
  } */
}

@media screen and (max-width: 552px) {
  
  /* .flotante-img {
    transform: translateY(100px) translateX(150px);
    font-size: 2rem;
  } */
}
</style>
