<template>
  <div class="prueba">
    <NavbarMain />
    <SliderCustom />
    <Footer />
  </div>
</template>

<script>
import NavbarMain from "@/components/NavbarMain.vue";
import SliderCustom from "@/components/SliderCustom.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Prueba",

  data() {
    return {};
  },

  components: {
    NavbarMain,
    SliderCustom,
    Footer,
  },

  created: function() {},

  methods: {
    verPromo: function() {},
  },
};
</script>
