<template>
  <div>
    <NavbarMain />
    <img src="@/assets/conocerutas1400.jpg" class="img-fluid" alt="..." />
    <div class="container">
      <div class="form-row">
          <h3 class="tittle-bln-xl mt-4">
            Novedades
          </h3>
        </div>

      <!-- <div class="titulo">
        <p>Novedades</p>
      </div> -->

      <div v-show="showProgressNovedades">
        <ProgressBar mode="indeterminate" style="height: 0.5em" />
      </div>

      <div class="contenedor-noticias" v-show="!showProgressNovedades">
        <div
          v-for="publicacion in publicaciones"
          :key="publicacion.id"
          class="noticia"
        >
          <Noticias
            :imagen="publicacion.imagen"
            :titulo="publicacion.titulo"
            :fecha="publicacion.fecha"
            :id_publicacion="publicacion.id"
          />
        </div>
      </div>

      <div class="contenedor-noticias" v-show="showProgressNovedades">
        <Skeleton height="18rem" v-show="showProgressNovedades"></Skeleton>
        <Skeleton height="18rem" v-show="showProgressNovedades"></Skeleton>
        <Skeleton height="18rem" v-show="showProgressNovedades"></Skeleton>
        <Skeleton height="18rem" v-show="showProgressNovedades"></Skeleton>
        <Skeleton height="18rem" v-show="showProgressNovedades"></Skeleton>
        <Skeleton height="18rem" v-show="showProgressNovedades"></Skeleton>
      </div>

    </div>
    <Footer />
  </div>
</template>

<script>
import NavbarMain from "@/components/NavbarMain.vue";
import Noticias from "@/components/Noticias.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      publicaciones: [],
      showProgressNovedades: false,
    };
  },

  components: {
    NavbarMain,
    Noticias,
    Footer,
  },

  created: function () {
    this.getnovedades();
  },

  computed: {
    imagenpublicacion() {
      return {
        ...this.publicacion,
        icon:
          this.publicacion.imagen &&
          require(`@/assets/${this.publicacion.imagen}`),
      };
    },
  },

  methods: {
    getnovedades: async function () {
      let vue = this;
      this.showProgressNovedades = true;
      await this.axios
        .get("model/publicaciones.php?dato=getpublicacionesapi")
        .then(function (response) {
          vue.publicaciones = response.data;
        })
        .catch((error) => {
          console.log("ERROR: " + error);
          console.log("ERROR responseText: " + error.responseText);
          console.log("ERROR STATUS: " + error.status);
          console.log("ERROR TEXT_STATUS: " + error.textStatus);
        })
        .then(function () {
          vue.showProgressNovedades = false;
        });
    },
  },
};
</script>

<style>
.contenedor-noticias {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.noticia {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* border: 5px solid red; */
  /* background: #004d27; */
  background-image: linear-gradient(to bottom, var(--green-blnv), white);
  border-radius: 10px;
  /* border: 3px solid red; */

  /* border-bottom: 1px solid #004d27; */
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.6);
}

.noticia:hover{
  scale: 1.05;
}

.titulo p {
  color: #004d27;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.grid_noticias {
  margin-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .contenedor-noticias {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .contenedor-noticias {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 552px) {
  .contenedor-noticias {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
