//Funciones de Validación
function validarCedula(cedula) {
  var numero = parseInt(cedula, 10);
  if (typeof numero === "number") {
    if (numero % 1 == 0) {
      if (numero > 999999 && numero <= 9999999999) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function validarTelefono(telefono) {
  var numero = parseInt(telefono, 10);
  if (typeof numero === "number") {
    if (numero % 1 == 0) {
      if (numero > 1111111 && numero <= 4000000000) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function getPesoMaxFiles() {
  return 2000;
}

function getBaseMegas() {
  return 1024;
}

function validarCorreo(correo) {
  var emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  if (emailRegex.test(correo)) {
    return true;
  } else {
    return false;
  }
}

function validarCelular(telefono){
  var numero = parseInt(telefono, 10);
  if (typeof numero === "number") {
    if (numero % 1 == 0) {
      if (numero > 3000000000 && numero <= 3259999999) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

//Funciones de Alertas
function getMessageWAR(mensaje){
  this.$swal.fire("Advertencia!", mensaje, "warning");
}

//Funciones de Response Backend
function getResponseOKInsert() {
  return "TRANSACIÓN EXITOSA";
}

function printConsole(error) {
  console.log("ERROR: " + error);
  console.log("ERROR responseText: " + error.responseText);
  console.log("ERROR STATUS: " + error.status);
  console.log("ERROR TEXT_STATUS: " + error.textStatus);
}

function confirmInsert(response){
  if (response.data.includes('TRABAJO EXITOSO...')) {
    return true;
  } else{
    return false;
  }
}

function confirmTransactOk(response){
  if (response.data.includes('TRANSACCIÓN EXITOSA...')) {
    return true;
  } else{
    return false;
  }
}

function formatCurrency(value) {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
}

function confirmSendEmail(response){
  if (response.data.includes('SE HA NOTIFICADO POR MAIL')) {
    return 'SE ENVIÓ UN MENSAJE AL AREA DE ATENCIÓN AL CLIENTE';
  } else{
    return '';
  }
}

function confirmSendEmailHV(response){
  if (response.data.includes('SE HA NOTIFICADO POR MAIL')) {
    return 'SE NOTIFICÓ AL AREA DE SELECCIÓN';
  } else{
    return '';
  }
}

export default {
  validarCedula,
  validarTelefono,
  validarCorreo,
  validarCelular,
  getResponseOKInsert,
  printConsole,
  getMessageWAR,
  confirmInsert,
  confirmTransactOk,
  confirmSendEmail,
  getPesoMaxFiles,
  getBaseMegas,
  confirmSendEmailHV,
  formatCurrency
};
