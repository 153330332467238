<template>
    <div>
      <NavbarMain />
      <img src="@/assets/politicas.jpg" class="img-fluid" alt="..." />
      <div class="container">
        <div class="titulopoliticas">
          <p>
            Politicas y Normatividades de la Compañía Libertador SA
          </p>
        </div>
        <div class="politicas">
          <Accordion>
            <AccordionTab
              header="POLITICA DE USO DE ELEMENTOS DE PROTECCION PERSONAL"
            >
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >La
                  <strong
                    >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                    S.A.&rdquo;&nbsp;</strong
                  >se compromete a brindar a todos los trabajadores de la
                  Compa&ntilde;&iacute;a los adecuados elementos de
                  protecci&oacute;n personal para realizar de forma adecuada y
                  segura todas las actividades laborales. Esto se realiza con el
                  fin de prevenir la materializaci&oacute;n de accidentes de
                  trabajo y enfermedades laborales garantizando est&aacute;ndares
                  de seguridad, salud y el bienestar f&iacute;sico y mental de
                  todos los trabajadores. Dichos elementos de protecci&oacute;n
                  personal ser&aacute;n entregados acorde a los peligros
                  existentes en la compa&ntilde;&iacute;a identificados
                  previamente en la Matriz de Identificaci&oacute;n de Peligros y
                  Valoraci&oacute;n de Riesgos seg&uacute;n la Gu&iacute;a
                  t&eacute;cnica Colombiana (GTC) 45.</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >&nbsp;</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >Es responsabilidad y compromiso del trabajador, utilizar los
                  elementos de protecci&oacute;n personal de forma adecuada y
                  mantenerlos y conservarlos en perfecto estado para la correcta
                  ejecuci&oacute;n de las actividades de trabajo. De igual forma,
                  los trabajadores se comprometen a:</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >&nbsp;</span
                >
              </p>
              <ul style="list-style-type: disc;">
                <li>
                  <span
                    style='font-family:"Arial",sans-serif;color:black;font-size:15px;color:black;'
                    >El empleado debe reportar &nbsp;a su superior cuando requiera
                    los elementos de protecci&oacute;n personal por deterioro o
                    perdida; la entrega de estos elementos no se encuentra
                    limitada y se registrara de acuerdo a lo especificado en la
                    matriz de identificaci&oacute;n de peligros y
                    valoraci&oacute;n del riesgo.</span
                  >
                </li>
                <li>
                  <span
                    style='font-family:"Arial",sans-serif;color:black;font-size:15px;color:black;'
                    >Verificar antes y despu&eacute;s de la actividad laboral
                    diaria, el perfecto estado de los elementos de
                    protecci&oacute;n personal.</span
                  >
                </li>
                <li>
                  <span
                    style='font-family:"Arial",sans-serif;color:black;font-size:15px;color:black;'
                    >Reportar al jefe inmediato y al Departamento de seguridad y
                    salud en el trabajo el no uso o uso inadecuado de los
                    elementos de protecci&oacute;n personal por parte de los
                    dem&aacute;s trabajadores de la Compa&ntilde;&iacute;a.</span
                  >
                </li>
                <li>
                  <span
                    style='font-family:"Arial",sans-serif;color:black;font-size:15px;color:black;'
                    >La compa&ntilde;&iacute;a vigilara el uso adecuado y estado
                    de los elementos de protecci&oacute;n personal, conforme a la
                    inducci&oacute;n del SG SST.&nbsp;</span
                  >
                </li>
              </ul>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >&nbsp;</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >&nbsp;</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >Esta pol&iacute;tica de regulaci&oacute;n del comportamiento
                  humano en &nbsp;seguridad vial, &nbsp;ser&aacute; revisada
                  anualmente y se comunicar&aacute; a todas las personas que
                  trabajen para COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.
                  &ldquo;COLIBERTADOR S.A.&rdquo; o en nombre de la misma, estando
                  a disposici&oacute;n del p&uacute;blico en general y
                  dem&aacute;s partes interesadas.</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >&nbsp;</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >&nbsp;</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >Dado en Santa Marta a los 9 d&iacute;as del mes de Enero de
                  2020.</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:center;'
              >
                <strong
                  ><span
                    style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                    >&nbsp;</span
                  ></strong
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:center;'
              >
                <strong
                  ><span
                    style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                    >COMPA&Ntilde;&Iacute;A LIBERTADOR S. A</span
                  ></strong
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:center;'
              >
                <strong
                  ><span
                    style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                    >&nbsp;&ldquo;COLIBERTADOR S.A.&rdquo;</span
                  ></strong
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >&nbsp;</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:justify;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >&nbsp;</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >&nbsp;</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:center;'
              >
                <span
                  style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                  >______________________________________</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:13px;font-family:"Times New Roman",serif;text-align:center;'
              >
                <strong
                  ><span
                    style='font-size:15px;font-family:"Arial",sans-serif;color:black;'
                    >WILSON GERARDO SILVA CORTES</span
                  ></strong
                >
              </p>
            </AccordionTab>
            <AccordionTab header="POLITICA DE NO USO DE EQUIPOS DE COMUNICACIÓN">
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
              >
                <span style='font-family:"Arial",sans-serif;'
                  >La <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A</strong>.
                  siendo consciente de la alta peligrosidad que representa
                  conducir un veh&iacute;culo y usar equipos de
                  comunicaci&oacute;n al mismo tiempo y teniendo en cuenta los
                  efectos negativos en la seguridad al conducir o al estar dentro
                  del veh&iacute;culo en la prestaci&oacute;n del servicio se
                  encuentre estacionado o en marcha, proh&iacute;be expresamente
                  el uso de dispositivos m&oacute;viles tales como
                  tel&eacute;fonos inteligentes, celulares, mp3 o mp4,
                  reproductores con aud&iacute;fonos, iPad&acute;s, &nbsp;tablets,
                  radios de telecomunicaciones, Avantel y dem&aacute;s que
                  ocasione la distracci&oacute;n de la psiquis del conductor. Lo
                  anterior tambi&eacute;n incluye la prohibici&oacute;n de leer o
                  responder mensajes de texto o sostener chats.</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
              >
                <span style='font-family:"Arial",sans-serif;'>&nbsp;</span>
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
              >
                <span style='font-family:"Arial",sans-serif;'
                  >Para comunicar cualquier anomal&iacute;a el operador o
                  conductor del veh&iacute;culo deber&aacute; verificar la
                  posibilidad de detener la marcha aplicando las medidas
                  necesarias de prevenci&oacute;n a otros actores de la v&iacute;a
                  como las luces estacionarias, conforme lo establece las normas
                  de tr&aacute;nsito, asegurando que no se estacione en
                  v&iacute;as prohibidas que le puedan ocasionar la
                  imposici&oacute;n de una infracci&oacute;n de tr&aacute;nsito o
                  un accidente por imprudencia al momento de
                  estacionarse.&nbsp;</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
              >
                <span style='font-family:"Arial",sans-serif;'>&nbsp;</span>
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
              >
                <span style='font-family:"Arial",sans-serif;'
                  >En todo caso, para atender o realizar llamadas con destino al
                  Coordinador de Rodamiento, con ocasi&oacute;n al ejercicio de
                  sus funciones, se preferir&aacute; &eacute;stas sean realizadas
                  o atendidas por el auxiliar de viaje.</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
              >
                <span style='font-family:"Arial",sans-serif;'>&nbsp;</span>
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;'
              >
                <span style='font-family:"Arial",sans-serif;'
                  >El incumplimiento de esta pol&iacute;tica dar&aacute; lugar a
                  las acciones disciplinarias conducentes.&nbsp;</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'
              >
                <span style='font-family:"Arial",sans-serif;'>&nbsp;</span>
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'
              >
                <span style='font-family:"Arial",sans-serif;'
                  >Esta pol&iacute;tica de regulaci&oacute;n del comportamiento
                  humano en &nbsp;seguridad vial, &nbsp;ser&aacute; revisada
                  anualmente y se comunicar&aacute; a todas las personas que
                  trabajen para
                  <strong
                    >COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR
                    S.A.&rdquo;&nbsp;</strong
                  >o en nombre de la misma, estando a disposici&oacute;n del
                  p&uacute;blico en general y dem&aacute;s partes
                  interesadas.</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'
              >
                <span style='font-family:"Arial",sans-serif;'>&nbsp;</span>
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'
              >
                <span style='font-family:"Arial",sans-serif;'>&nbsp;</span>
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'
              >
                <span style='font-family:"Arial",sans-serif;'
                  >Dado en Santa Marta a los 9 d&iacute;as del mes de Enero de
                  2020.</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'
              >
                <span style='font-family:"Arial",sans-serif;'>&nbsp;</span>
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'
              >
                <strong
                  ><span style='font-family:"Arial",sans-serif;'
                    >&ldquo;En la COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.,</span
                  ></strong
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'
              >
                <strong
                  ><span style='font-family:"Arial",sans-serif;'
                    >&iexcl;Tu seguridad, Mi movilidad!&rdquo;</span
                  ></strong
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'
              >
                <span style='font-family:"Arial",sans-serif;'>&nbsp;</span>
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'
              >
                <span style='font-family:"Arial",sans-serif;'>&nbsp;</span>
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'
              >
                <span style='font-family:"Arial",sans-serif;'
                  >______________________________________</span
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'
              >
                <strong
                  ><span style='font-family:"Arial",sans-serif;'
                    >WILSON GERARDO &nbsp;SILVA CORTES</span
                  ></strong
                >
              </p>
              <p
                style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'
              >
                <strong
                  ><span style='font-family:"Arial",sans-serif;'
                    >Gerente</span
                  ></strong
                >
              </p>
            </AccordionTab>
            <AccordionTab header="POLITICA REGULACIÓN DE LA VELOCIDAD">
              <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Es pol&iacute;tica de la <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A</strong>,, &nbsp;dar cumplimiento a las Normas de Tr&aacute;nsito aplicables a los l&iacute;mites de velocidad &nbsp;definidos en los Art&iacute;culos 106 y 107 del C&oacute;digo Nacional de Tr&aacute;nsito de Colombia y las Autoridades de transito de cada localidad; por lo tanto, se establece:</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Las personas que conduzcan &nbsp;veh&iacute;culos que tengan relaci&oacute;n directa o indirecta con la Compa&ntilde;&iacute;a &nbsp; &nbsp;deben respetar los l&iacute;mites de velocidad establecidos por la ley y los organismos de tr&aacute;nsito de cada localidad conservando la velocidad m&aacute;xima permitida de <strong>80 km/h&nbsp;</strong>en carretera pavimentada y en buen estado, siempre y cuando, en la v&iacute;a no existan se&ntilde;ales restrictivas de velocidad.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Los conductores deben reducir la velocidad a <strong>30 km/h</strong> en lugares de concentraci&oacute;n de personas, &nbsp;zonas escolares y residenciales.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>La circulaci&oacute;n interna de los veh&iacute;culos que tengan relaci&oacute;n directa o indirecta con la Compa&ntilde;&iacute;a es de <strong>10 Km/h</strong> dentro de &nbsp;las instalaciones propias, parqueaderos autorizados y Terminales de transporte.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Estos l&iacute;mites de velocidad deben reducirse en caso de que las condiciones clim&aacute;ticas y ambientales, la infraestructura vial, el estado de las v&iacute;as, y la visibilidad as&iacute; lo requieran.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Para el efectivo control de la misma se realizar&aacute; el seguimiento a las &nbsp;infracciones de tr&aacute;nsito por exceso de velocidad llevando a cabo un control mensual de la reincidencia que permita retroalimentar los comportamientos viales, as&iacute; como el seguimiento mediante la plataforma de &nbsp; monitoreo satelital de las alertas de velocidad en los casos en que los conductores excedan los l&iacute;mites establecidos &nbsp; de la presente pol&iacute;tica.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Esta pol&iacute;tica de regulaci&oacute;n del comportamiento humano en &nbsp;seguridad vial, &nbsp;ser&aacute; revisada anualmente y se comunicar&aacute; a todas las personas que trabajen para <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR S.A.&rdquo;&nbsp;</strong>o en nombre de la misma, estando a disposici&oacute;n del p&uacute;blico en general y dem&aacute;s partes interesadas.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Dado en Santa Marta a los 9 d&iacute;as del mes de Enero de 2020.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:"Arial",sans-serif;'>&ldquo;En la COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.,</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:"Arial",sans-serif;'>&iexcl;Tu seguridad, Mi movilidad!&rdquo;</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:"Arial",sans-serif;'>______________________________________</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:"Arial",sans-serif;'>WILSON GERARDO &nbsp;SILVA CORTES</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:"Arial",sans-serif;'>Gerente</span></strong></p>
                      
            </AccordionTab>
            <AccordionTab header="POLITICA DE USO DEL CINTURÓN DE SEGURIDAD">
              <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>La <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR S.A.&rdquo;</strong>. teniendo como fundamento la Resoluci&oacute;n 19200 del 20 de diciembre de 2002, por la cual se reglamenta el uso e instalaci&oacute;n del cintur&oacute;n de seguridad de acuerdo con el art&iacute;culo 82 del C&oacute;digo Nacional de Tr&aacute;nsito Terrestre y conforme lo se&ntilde;ala la norma todos los veh&iacute;culos automotores que transiten por las v&iacute;as del territorio nacional, incluyendo las urbanas, deber&aacute;n portar en los asientos delanteros el cintur&oacute;n de seguridad, a su vez contempla que el uso del cintur&oacute;n de seguridad es obligatorio para todos los veh&iacute;culos automotores, a trav&eacute;s de la presente pol&iacute;tica se establece la obligatoriedad del uso del cintur&oacute;n de seguridad debidamente abrochado para todos los conductores y pasajeros en los asientos delanteros y en la primera fila de la cabina durante todo el recorrido.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;Es responsabilidad del conductor antes de poner en marcha el veh&iacute;culo, dedicar unos segundos a colocarse el cintur&oacute;n de seguridad correctamente y hacer la recomendaci&oacute;n a los pasajeros que deban usarlo.&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>Por lo anterior,&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <ul style="list-style-type: undefined;margin-left:26px;">
                              <li><span style='font-family:"Arial",sans-serif;'>Los cinturones de seguridad deben estar en buen estado de funcionamiento y deben estar disponibles a la vista de su usuario</span></li>
                              <li><span style='font-family:"Arial",sans-serif;'>Los menores de diez (10) a&ntilde;os no podr&aacute;n viajar en el asiento delantero del veh&iacute;culo.</span></li>
                              <li><span style='font-family:"Arial",sans-serif;'>Los mayores de &nbsp;diez a&ntilde;os podr&aacute;n viajar en el asiento delantero del veh&iacute;culo siempre y cuando utilicen el cintur&oacute;n de seguridad debidamente abrochado durante todo el recorrido.</span></li>
                          </ul>
                          <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:36.0pt;font-size:15px;font-family:"Arial",sans-serif;'>&nbsp;</p>
                          <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:36.0pt;font-size:15px;font-family:"Arial",sans-serif;'>&nbsp;</p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Esta pol&iacute;tica de regulaci&oacute;n del comportamiento humano en &nbsp;seguridad vial, &nbsp;ser&aacute; revisada anualmente y se comunicar&aacute; a todas las personas que trabajen para <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR S.A.&rdquo;&nbsp;</strong>o en nombre de la misma, estando a disposici&oacute;n del p&uacute;blico en general y dem&aacute;s partes interesadas.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Dado en Santa Marta a los 9 d&iacute;as del mes de Enero de 2020.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'><strong><span style='font-family:"Arial",sans-serif;'>&ldquo;En la COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.,</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'><strong><span style='font-family:"Arial",sans-serif;'>&iexcl;Tu seguridad, Mi movilidad!&rdquo;</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>______________________________________</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Arial",sans-serif;'>WILSON GERARDO &nbsp;SILVA CORTES</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Arial",sans-serif;'>Gerente</span></strong></p>
                      
            </AccordionTab>
            <AccordionTab header="POLITICA DE REGULACION DE HORAS DE CONDUCCION Y DE DESCANSO">
              <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;margin-left:36.0pt;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>La <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR S.A.&rdquo;&nbsp;</strong>como objetivo fundamental dentro de su pol&iacute;tica de seguridad vial, contempla la importancia de&nbsp;</span><span style='font-family:"Arial",sans-serif;'>prevenir situaciones de riesgo en la conducci&oacute;n por efectos de fatiga o cansancio acumulado, para lo cual programa tiempos m&aacute;ximos de conducci&oacute;n de 4 horas continuas, con pausas activas y estiramiento muscular de hasta 15 minutos.&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;margin-left:36.0pt;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;margin-left:36.0pt;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>Al t&eacute;rmino de la jornada laboral, que ser&aacute; de 8 horas m&aacute;ximo de conducci&oacute;n, se exigir&aacute; al conductor un periodo m&iacute;nimo de descanso de 8 horas antes de volver a iniciar su jornada de conducci&oacute;n.&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;margin-left:36.0pt;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;margin-left:36.0pt;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>Es pol&iacute;tica de la Compa&ntilde;&iacute;a otorgar los d&iacute;as de descanso conforme a las&nbsp;</span><span style='font-family:"Arial",sans-serif;'>disposiciones legales contenidas en el C&oacute;digo sustantivo del trabajo.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;margin-left:36.0pt;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;margin-left:36.0pt;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Esta pol&iacute;tica de regulaci&oacute;n del comportamiento humano en &nbsp;seguridad vial, &nbsp;ser&aacute; revisada anualmente y se comunicar&aacute; a todas las personas que trabajen para <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR S.A.&rdquo;&nbsp;</strong>o en nombre de la misma, estando a disposici&oacute;n del p&uacute;blico en general y dem&aacute;s partes interesadas.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Dado en Santa Marta a los 9 d&iacute;as del mes de Enero de 2020.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'><strong><span style='font-family:"Arial",sans-serif;'>&ldquo;En la COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.,</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'><strong><span style='font-family:"Arial",sans-serif;'>&iexcl;Tu seguridad, Mi movilidad!&rdquo;</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>______________________________________</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Arial",sans-serif;'>WILSON GERARDO &nbsp;SILVA CORTES</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Arial",sans-serif;'>Gerente&nbsp;</span></strong></p>
                      
            </AccordionTab>
            <AccordionTab header="POLITICA DE NO CONSUMO DE ALCOHOL, TABACO Y SUSTANCIAS PSICOACTIVAS">
              <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>En <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A</strong>. <strong>&ldquo;COLIBERTADOR S.A.&rdquo;</strong>, <strong>&nbsp;</strong>somos conscientes que el uso de alcohol, las sustancias psicoactivas y el tabaco, causan efectos adversos en la salud, afecta la seguridad de los actores de la v&iacute;a, as&iacute; como la eficiencia y productividad de los empleados. Por lo tanto <strong><u>proh&iacute;be</u>&nbsp;</strong>el consumo, uso, fabricaci&oacute;n, distribuci&oacute;n, venta, transporte, promoci&oacute;n, ocultamiento, transferencia o almacenamiento de sustancias no autorizadas, prohibidas, ilegales o controladas, inhalantes y sustancias embriagantes (alcohol y drogas) a sus empleados, proveedores, contratistas, subcontratistas y visitantes,&nbsp;</span><span style='font-family:"Arial",sans-serif;'>en &aacute;reas donde se desarrollen actividades directas de la empresa, sean instalaciones propias o contratadas, como tambi&eacute;n al interior de los veh&iacute;culos previo, durante y posterior a su conducci&oacute;n.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>Debido a que el consumo de este tipo de sustancias constituye un factor de riesgo que afecta la integridad de los trabajadores y la vida de los usuarios que utilizan nuestros servicios, la COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR S.A.&rdquo; establece que se realizar&aacute;n pruebas de drogas peri&oacute;dicas de manera aleatoria al personal. Igualmente establece como pol&iacute;tica y en cumplimiento de las disposiciones que reglamentan la actividad transportadora y de uso de los terminales de transporte, realizar diariamente pruebas de alcoholimetr&iacute;a previa a cada despacho de forma permanente.&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>Por consiguiente, se establecen las siguientes pol&iacute;ticas de autocontrol:</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>La prueba de alcoholimetr&iacute;a en aire expirado debe ser negativa cuando su valor es 0 mg.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>La tolerancia de alcohol en sangre es 0.&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>La prueba de sustancias psicoactivas deber&aacute; ser negativa.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>Las sanciones en caso de resultar positiva algunas de las pruebas, se encuentran descritas en el Reglamento Interno de Trabajo y el Reglamento Operativo de la Compa&ntilde;&iacute;a teniendo en cuenta que la actividad de conducci&oacute;n de servicio p&uacute;blico es considerada de peligro y est&aacute; prohibida por las leyes vigentes.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;line-height:115%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Esta pol&iacute;tica de regulaci&oacute;n del comportamiento humano en &nbsp;seguridad vial, &nbsp;ser&aacute; revisada anualmente y se comunicar&aacute; a todas las personas que trabajen para <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR S.A.&rdquo;&nbsp;</strong>o en nombre de la misma, estando a disposici&oacute;n del p&uacute;blico en general y dem&aacute;s partes interesadas.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>Dado en Santa Marta a los 9 d&iacute;as del mes de Enero de 2020.</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-indent:35.4pt;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;line-height:115%;'><strong><span style='font-family:"Arial",sans-serif;'>&ldquo;</span></strong><span style='font-family:"Arial",sans-serif;'>En la&nbsp;</span><strong><span style='font-family:"Arial",sans-serif;'>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.</span></strong><span style='font-family:"Arial",sans-serif;'>, trabajamos con nuestros sentidos</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;line-height:115%;'><span style='font-family:"Arial",sans-serif;'>&nbsp;libre de alcohol y drogas&rdquo;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;line-height:115%;'><strong><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></strong></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:justify;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
                          <p style='margin:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Arial",sans-serif;'>WILSON GERARDO SILVA CORTES</span></strong></p>
                          <p><strong><span style='font-size:15px;line-height:115%;font-family:"Arial",sans-serif;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Gerente</span></strong></p>
                      
            </AccordionTab>
            <AccordionTab header="POLÍTICA DE CALIDAD">
              <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>La <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A.</strong> se compromete a prestar el servicio p&uacute;blico de transporte terrestre automotor de pasajeros por carretera cumpliendo todos los requisitos aplicables al servicio, optimizando los recursos y mejorando continuamente el sistema de gesti&oacute;n de la calidad con el objetivo de aumentar la satisfacci&oacute;n de nuestros clientes.</span></p>
            </AccordionTab>
            <AccordionTab header="POLÍTICA SEGURIDAD Y SALUD EN EL TRABAJO">
              <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
                              <span style='font-family:"Arial",sans-serif;'>La <strong>COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR S.A.&rdquo;</strong> y la Alta Direcci&oacute;n garantizaran la identificaci&oacute;n, evaluaci&oacute;n, control y/o eliminaci&oacute;n de los riesgos presentes en todas las actividades desarrolladas en la organizaci&oacute;n, as&iacute; como el mejoramiento continuo en su gesti&oacute;n de Prevenci&oacute;n de Riesgos Laborales y viales promoviendo la participaci&oacute;n y consulta de los trabajadores, cumpliendo con la legislaci&oacute;n vigente, manteniendo altos est&aacute;ndares de seguridad y salud en el trabajo.</span></p>
            </AccordionTab>
            <AccordionTab header="POLÍTICA SOSTENIBILIDAD">
              <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>La COMPA&Ntilde;&Iacute;A LIBERTADOR S.A. &ldquo;COLIBERTADOR S.A.&rdquo; se compromete a controlar y optimizar los recursos h&iacute;dricos, energ&eacute;ticos y lograr el aprovechamiento de los residuos s&oacute;lidos, a brindar capacitaci&oacute;n a sus colaboradores en temas ambientales, socioculturales y econ&oacute;micos que ayuden en la minimizaci&oacute;n de los impactos negativos producidos por nuestras actividades; adem&aacute;s sensibilizar a sus partes interesadas en la conservaci&oacute;n y protecci&oacute;n del medio ambiente, entre otros temas sostenibles.</span></p>
            </AccordionTab>
            <AccordionTab header="POLÍTICA DE PLAN ESTRATÉGICO DE SEGURIDAD VIAL">
              <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Arial",sans-serif;'>La COMPA&Ntilde;&Iacute;A LIBERTADOR S.A., como parte integral del tr&aacute;fico vial en la prestaci&oacute;n del servicio p&uacute;blico de transporte terrestre automotor de pasajeros por carretera, se compromete desde la Alta Direcci&oacute;n, a implementar programas de promoci&oacute;n de h&aacute;bitos y comportamientos seguros, que conlleven a la prevenci&oacute;n, control y mitigaci&oacute;n de los factores del riesgo de accidentes e incidentes de tr&aacute;nsito, en los que se afecte la salud de pasajeros, peatones y/o conductores, en desarrollo de la misi&oacute;n como en el desplazamiento de su personal desde y hacia el sitio de trabajo, a trav&eacute;s de exigentes procesos de selecci&oacute;n, permanente sensibilizaci&oacute;n en el cumplimento de las normas y se&ntilde;ales de tr&aacute;nsito y capacitaci&oacute;n en competencias laborales con &eacute;nfasis en seguridad vial; proporcionando los recursos necesarios para el alistamiento de los equipos, el mantenimiento preventivo y correctivo de los veh&iacute;culos, el monitoreo satelital de los comportamientos viales, asegurando la movilidad en las v&iacute;as internas y externas con una infraestructura segura e implementando protocolos de atenci&oacute;n a las v&iacute;ctimas ante emergencias; cumpliendo con las disposiciones de la Ley 1503 de 2011, la legislaci&oacute;n vigente aplicable y los requisitos pactados; mediante procesos orientados al auto control y a la mejora continua.</span></p>
            </AccordionTab>
            <AccordionTab header="CONTRATO DE TRANSPORTE">
             <a href="http://www.berlinave.com/ContratoDeTransporte.pdf">Clic para descargar contrato de transporte</a>
            </AccordionTab>
            <AccordionTab header="POLITICA DE TRATAMIENTO DE DATOS PERSONALES">
              <a href="http://www.berlinave.com/ProteccinDatosPersonales.pdf">Clic para descargar políticas de proteción de datos personales</a>
            </AccordionTab>
            <AccordionTab header="PUNTOS ESTRATÉGICOS PARA ABORDAJE Y CONTROL DE PASAJEROS">
              <a href="http://www.berlinave.com/puntos_estrategicos_para_abordaje.pdf">Clic para descargar puntos de abordaje y control</a>
            </AccordionTab>
            <AccordionTab header="PROMOCIÓN IDA Y REGRESO">
              <embed src="http://www.berlinave.com/PROMOCION_IDA_REGRESO.pdf" type="application/pdf" width="100%" height="600px" />
            </AccordionTab>
          </Accordion>
        </div>
      </div>
      <Footer />
    </div>
  </template>
  
  <script>
  import Accordion from "primevue/accordion";
  import AccordionTab from "primevue/accordiontab";
  
  //import Dropdown from "primevue/dropdown";
  
  //import InputText from "primevue/inputtext";
  /*
  import Dropdown from "primevue/dropdown";
  import AutoComplete from "primevue/autocomplete";
  import Calendar from "primevue/calendar";
  import CascadeSelect from "primevue/cascadeselect";
  */
  import NavbarMain from "@/components/NavbarMain.vue";
  import Footer from "@/components/Footer.vue";
  
  export default {
    data() {
      return {
        showProgressRutas: false,
        origenes: [],
        rutas: [],
        destinos: [],
        rutaorigen: 0,
        rutadestino: 0,
      };
    },
  
    created: function() {
  
    },
  
    computed: {},
  
    methods: {},
  
    components: {
      NavbarMain,
      Footer,
      Accordion,
      AccordionTab,
    },
  };
  </script>
  
  <style>
  .titulopoliticas p {
    color: #004d27;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
    margin-top: 10px;
  }
  
  .politicas {
    margin-top: 20px;
  }
  
  .btn-berlinave {
    background-color: #004d27 !important;
    color: white !important;
  }
  </style>
  