<template>
  <div class="container">
    <div class="form-group">
      <label for="exampleFormControlFile1">Example file input</label>
      <input
        type="file"
        class="form-control-file"
        id="exampleFormControlFile1"
        @change="onFileChange"
        multiple
      />
    </div>
    <div class="form-group">
      <button type="button" @click="subirArchivo" class="btn btn-primary">Primary</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedFiles: [],
      sizeSelectedFiles: 0,
      imagen: null,
    };
  },

  methods: {
    subirArchivo: async function() {
      let formData = new FormData();
      formData.append("file", this.selectedFile);

      for (var i = 0; i < this.sizeSelectedFiles; i++) {
            //form_data.append('file[]', $('#files_fondoauxmutuo').prop('files')[i]);
            formData.append("file[]", this.selectedFiles[i]);
            console.log('ADD FILE: ' +  this.selectedFiles[i]);
        }

      await this.axios
        .post(
          "https://api.solincosta.com/model/empleado.php?dato=putfile",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function(response) {
          console.log(response.data);
        })
        .catch((error) => {
          console.log("ERROR: " + error);
          console.log("ERROR responseText: " + error.responseText);
          console.log("ERROR STATUS: " + error.status);
          console.log("ERROR TEXT_STATUS: " + error.textStatus);
        })
        .then(function() {});
    },

    onFileChange(event) {
      // Handle files like:
      console.log(event);
      console.log('SIZE: ' + event.target.files.length);
      this.sizeSelectedFiles = event.target.files.length;
      this.selectedFiles = event.target.files;
      console.log('FILES: ' + event.target.files);
      console.log('FILE 5: ' + event.target.files[4]);
      //this.selectedFile = event.target.files[0];
      //var variable = event.files;
      //console.log('Target ' + variable)
    },
  },

  mounted() {},
};
</script>
