<template>
  <div>
    <NavbarMainSE />

    <div class="container formorigendestino" style="zoom: 80%">
      <h3 class="alineacionizquierda">SOLICITUD DE CERTIFICACIÓN</h3>
      <div class="grid">
        <div class="col-8">
          <Image
            src="https://www.berlinave.com/BERLINUXERPBLNA/png/sliderberlinastur.png"
            alt="Image Text"
            width="700"
          />

          <p class="alineacionizquierda">
            Si necesitas ayuda con la certificación de servicio, escríbenos al
            siguente correo info@berlinastur.com o contáctanos a los teléfonos
            (601) 7435050 - 318 3545454 - 318 6665544
          </p>
        </div>
        <div class="col-4">
          <b-card
            title="Generar certificación"
            img-top
            tag="article"
            style="max-width: 20rem"
            class="mb-2"
          >
            <label for="desde">Filtrar Desde</label>
            <input
              id="desde"
              class="form-control"
              placeholder="Fecha de inicial: 2022-01-01"
              v-model="buscarcertificado.perInicial"
              type="date"
            />

            <label for="hasta">Filtrar Hasta</label>

            <input
              id="hasta"
              class="form-control"
              placeholder="Fecha de final: 2022-01-01"
              v-model="buscarcertificado.perFinal"
              type="date"
            />

            <label for="view_certificado_documento">N° de identificación</label>
            <input
              class="form-control"
              type="text"
              v-model="buscarcertificado.documento"
              :readonly="block_documento"
              id="view_certificado_documento"
              placeholder="Consultar por documento"
              @keyup.enter="buscarCertificadoXDocumento"
              maxlength="10"
            />

            <div class="superior">
              <Button
                @click="buscarCertificadoXDocumento"
                :loading="loadingconsultarinfopersona"
                label="Buscar"
                icon="pi pi-search"
                iconPos="right"
              />
            </div>
          </b-card>
        </div>
      </div>

      <div v-show="showProgressCertificados">
        <ProgressBar mode="indeterminate" style="height: 0.5em" />
      </div>

      <div class="grid">
        <div class="col">
          <p v-show="showButtonLarge">
            Si la certificación es dirigida a una empresa por favor diligencie
            los campos: Nit y nombre, de lo contrario se generará a solicitud
            del interesado
          </p>
        </div>
      </div>

      <div class="grid">
        <div class="col">
          <input
            class="form-control"
            type="text"
            v-model="buscarcertificado.nit"
            v-show="showButtonLarge"
            id="nit_empresa"
            placeholder="Nit empresa"
            maxlength="12"
          />
        </div>
        <div class="col">
          <input
            class="form-control"
            type="text"
            v-model="buscarcertificado.empresa"
            v-show="showButtonLarge"
            id="nombre_empresa"
            placeholder="Nombre empresa"
            maxlength="50"
          />
        </div>

        <div class="col">
          <Button
            @click="generarMultiplesSeleccionados"
            :loading="loadinggeneracioncertificados"
            v-show="showButtonLarge"
            label=" Generar certificado de los viajes seleccionados"
            icon="pi pi-file-pdf"
            iconPos="right"
          />
        </div>
      </div>

      <div class="form-row formtoken">
        <input
          class="form-control"
          type="text"
          v-show="showTxtToken"
          v-model="buscarcertificado.token"
          id="view_certificado_token"
          autocomplete="off"
          placeholder="Ingrese el Token recibido y presione ENTER"
          @keyup.enter="consultarViajesXDocumento"
          maxlength="6"
          style="text-align: center"
        />
      </div>

      <div class="form-row">
        <table class="table table-striped">
          <thead style="background-color: #009e54; color: white">
            <tr>
              <th scope="col">FECHA</th>
              <th scope="col">HORA</th>
              <th scope="col">VOUCHER</th>
              <th scope="col">ORIGEN</th>
              <th scope="col">DESTINO</th>
              <th scope="col">SELECCIONAR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tiquete in tiquetes" :key="tiquete.numero">
              <td>{{ tiquete.Fecha }}</td>
              <td>{{ tiquete.Hora }}</td>
              <td>{{ tiquete.numero }}</td>
              <td>{{ tiquete.origen }}</td>
              <td>{{ tiquete.destino }}</td>
              <td>
                <Checkbox
                  :id="tiquete.numero"
                  name="tiquete"
                  :value="tiquete"
                  v-model="selectedTiquetes"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Dialog
      header="Header"
      :visible="showmodalvalidar"
      :modal="true"
      :style="{ width: '50vw' }"
      @update:visible="ocultarDialogVerificacionIdentidad"
    >
      <template #header>
        <h5>
          Seleccione una opción, recibirá un código para verificar su identidad
        </h5>
      </template>

      <p>
        Si no posee información de contacto registrada en nuestras bases de
        datos debe acercarse a una de nuestras oficinas, escribir al correo
        info@berlinastur.com o llamar a la linea de atención al cliente (605)
        3850030 – 3186665544 – 318 3545454 para actualizar sus datos y
        posteriormente puede intentar de nuevo el proceso de generación de
        certificados de servicio.
      </p>

      <br />

      <form>
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body">
              <form>
                <div class="form-row">
                  <div class="mensaje-habbeas-data"></div>
                </div>

                <div
                  v-for="category of categories"
                  :key="category.key"
                  class="field-radiobutton"
                >
                  <RadioButton
                    :id="category.key"
                    name="category"
                    :value="category"
                    v-model="selectedCategory"
                  />
                  <label :for="category.key">{{ category.name }}</label>
                </div>

                <div v-show="showProgressGenerandoToken">
                  <ProgressBar mode="indeterminate" style="height: 0.5em" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </form>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          v-show="tokenfrm.wait"
          animationDuration=".5s"
        />

        <Button
          icon="pi pi-check"
          autofocus
          :label="tokenfrm.botonlabel"
          :disabled="tokenfrm.botondisabled"
          @click="getToken"
        />
      </template>
    </Dialog>

    <FooterSE />
  </div>
</template>

<script>
const $ = require("jquery");
window.$ = $;
import NavbarMainSE from "@/components/NavbarMainSE.vue";
import FooterSE from "@/components/FooterSE.vue";

export default {
  data() {
    return {
      showmodalvalidar: false,
      showProgressCertificados: false,
      showTxtToken: false,
      showButtonLarge: false,
      loadingconsultarinfopersona: false,
      loadinggeneracioncertificados: false,

      tokenfrm: {
        wait: false,
        botonlabel: "Enviar Token",
        botondisabled: false,
      },

      buscarcertificado: {
        nit: "",
        empresa: "",
        documento: "",
        correo: "",
        telefono: 0,
        tiquete: "",
        perInicial: "",
        perFinal: "",
        token: "",
      },
      token: "",
      correo: "",
      telefono: "",
      eleccion: "",
      tiquetes: [],
      city: null,
      categories: [],
      selectedCategory: null,
      resp: [],

      selectedTiquetes: [],
      block_documento: false,

      showProgressGenerandoToken: false,
    };
  },

  compatConfig: { MODE: 3 },

  created: function () {},

  computed: {},

  methods: {
    ocultarDialogVerificacionIdentidad: function (value) {
      if (!value) {
        this.showmodalvalidar = false;
      }
    },

    buscarCertificadoXDocumento: async function () {
      var datosEnviar = {
        documento: this.buscarcertificado.documento,
        perInicial: this.buscarcertificado.perInicial,
        perFinal: this.buscarcertificado.perFinal,
      };

      var day1 = new Date(this.buscarcertificado.perInicial);
      var day2 = new Date(this.buscarcertificado.perFinal);

      var difference = day2.getTime() - day1.getTime();

      if (difference >= 0) {
        difference = Math.abs(day2 - day1);
        var days = difference / (1000 * 3600 * 24);

        if (days > 60) {
          alert("Debe seleccionar un rango máximo de dos meses ");
        } else {
          this.showProgressCertificados = true;
          this.loadingconsultarinfopersona = true;
          let vue = this;

          await this.axios
            .post(
              "model/certificadodeviajese.php?dato=getdatospasajero",
              JSON.stringify(datosEnviar)
            )
            .then(function (response) {
              if (response.data != "") {
                vue.showButtonLarge = true;
                vue.categories = response.data;
                vue.selectedCategory = vue.categories[0];

                vue.showTxtToken = false;
                vue.showButtonLarge = false;
                vue.showmodalvalidar = true;
                if (vue.categories[0] !== null) {
                  vue.buscarcertificado.correo = vue.categories[0].llave;
                }
              } else {
                alert("La consulta no arroja Información");
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .then(function () {
              vue.showProgressCertificados = false;
              vue.loadingconsultarinfopersona = false;
            });
        }
      } else {
        alert("El periodo de fecha inicial debe ser menor al periodo final");
      }
    },

    consultarViajesXDocumento: async function () {
      var datosEnviar = {
        documento: this.buscarcertificado.documento,
        token: this.buscarcertificado.token,
        perInicial: this.buscarcertificado.perInicial,
        perFinal: this.buscarcertificado.perFinal,
      };
      this.showProgressCertificados = true;
      let vue = this;
      await this.axios
        .post(
          "model/certificadodeviajese.php?dato=gettiquetesxcedulaapi",
          JSON.stringify(datosEnviar)
        )
        .then(function (response) {
          if (response.data.includes("TOKEN INVÁLIDO")) {
            vue.$swal.fire("Advertencia!", response.data, "warning");
          } else {
            vue.tiquetes = response.data;
            vue.showTxtToken = false;
            vue.showButtonLarge = true;
            vue.block_documento = true;
          }
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressCertificados = false;
        });
    },

    buscarCertificadoXTiquete: async function () {
      var datosEnviar = {
        tiquete: this.buscarcertificado.tiquete,
      };
      this.showProgressCertificados = true;
      let vue = this;
      await this.axios
        .post(
          "model/certificadodeviaje.php?dato=gettiquetesxtiqueteapi",
          JSON.stringify(datosEnviar)
        )
        .then(function (response) {
          vue.tiquetes = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressCertificados = false;
        });
    },

    getToken: async function () {
      var datosEnviar = {
        key: this.selectedCategory.key,
        llave: this.selectedCategory.llave,
      };

      if (this.selectedCategory.key === "C") {
        if (!this.$utilidad.validarCorreo(this.selectedCategory.llave)) {
          this.$swal.fire(
            "Advertencia!",
            "Correo inválido para el envío del Token, debe actualizar su correo en una de nuestras oficinas",
            "warning"
          );
        } else {
          this.showProgressGenerandoToken = true;
          this.tokenfrm.wait = true;
          this.tokenfrm.botonlabel = "Enviando token...";
          this.tokenfrm.botondisabled = true;
          let vue = this;
          await this.axios
            .post(
              "model/certificadodeviajese.php?dato=gettoken",
              JSON.stringify(datosEnviar)
            )
            .then(function (response) {
              if (response.data.includes("CLIENT: 250 2.1.5 Ok")) {
                vue.$swal.fire(
                  "Envío exitoso!",
                  "Se ha enviado un Token al correo seleccionado, si no aparece en su bandeja de entrada por favor verificar en Spam ",
                  "success"
                );
                vue.showTxtToken = true;
                vue.showmodalvalidar = false;
              } else {
                this.$swal.fire(
                  "Advertencia!",
                  "Se ha producido un error al enviar el Token, confirme su correo electronico en nuestras oficinas",
                  "warning"
                );
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .then(function () {
              vue.showProgressGenerandoToken = false;
              vue.tokenfrm.wait = false;
              vue.tokenfrm.botonlabel = "Enviar token";
              vue.tokenfrm.botondisabled = false;
            });
        }
      } else {
        if (!this.$utilidad.validarCelular(this.selectedCategory.llave)) {
          this.$swal.fire(
            "Advertencia!",
            "Numero de teléfono inválido para el envío del Token, debe actualizar su numero de teléfono en una de nuestras oficinas",
            "warning"
          );
        } else {
          this.showProgressGenerandoToken = true;
          this.tokenfrm.wait = true;
          this.tokenfrm.botonlabel = "Enviando token...";
          this.tokenfrm.botondisabled = true;
          let vue = this;
          await this.axios
            .post(
              "model/certificadodeviaje.php?dato=gettoken",
              JSON.stringify(datosEnviar)
            )
            .then(function (response) {
              //$("#modal-validar").modal("hide");
              if (
                !response.data.includes(
                  "NO SE PUDO ENVIAR EL TOKEN POR MENSAJE DE TEXO"
                )
              ) {
                vue.$swal.fire(
                  "Envío exitoso!",
                  "SE HA ENVIADO UN MENSAJE DE TEXTO AL TELEFONO SELECCIONADO ",
                  "success"
                );
                vue.showTxtToken = true;
                vue.showmodalvalidar = false;
              } else {
                this.$swal.fire(
                  "Advertencia!",
                  "Se ha producido un error al enviar el Token, confirme su número de teléfono registrado en nuestras oficinas",
                  "warning"
                );
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .then(function () {
              vue.showProgressGenerandoToken = false;
              vue.tokenfrm.wait = false;
              vue.tokenfrm.botonlabel = "Enviar token";
              vue.tokenfrm.botondisabled = false;
            });
        }
      }
    },

    generarMultiplesSeleccionados: async function () {
      if (this.selectedTiquetes.length !== 0) {
        this.showProgressCertificados = true;

        var cadena = "";
        for (var tiquete in this.selectedTiquetes) {
          var cadenaLimpia = this.selectedTiquetes[tiquete].numero.trim();
          cadena = cadena + "_" + cadenaLimpia;
        }

        var datosEnviar = {
          tiquetes: cadena,
          cedula: this.buscarcertificado.documento,
          perinicial: this.buscarcertificado.perInicial,
          perfinal: this.buscarcertificado.perFinal,
          nit: this.validarvacio(this.buscarcertificado.nit),
          empresa: this.validarvacio(this.buscarcertificado.empresa),
          correo: this.buscarcertificado.correo,
        };

        if (!this.$utilidad.validarCorreo(this.buscarcertificado.correo)) {
          this.$swal.fire(
            "Advertencia!",
            "Correo inválido para el envío del certificado, debe actualizar su correo en una de nuestras oficinas",
            "warning"
          );
        } else {
          let vue = this;
          await this.axios
            .post(
              "https://www.berlinave.com/BERLINUXERP/gettiquetesseleccionadossemail.php",
              JSON.stringify(datosEnviar)
            )
            .then(function (response) {
              if (response.data.includes("CLIENT: 250 2.1.5 Ok")) {
                vue.$swal.fire(
                  "Envío exitoso!",
                  "Se ha enviado una copia del certificado al correo registrado, si no aparece en su bandeja de entrada por favor verificar en Spam ",
                  "success"
                );
              } else {
                console.log("MAIL FAIL");
                vue.$swal.fire(
                  "Advertencia!",
                  "Se ha producido un error al enviar el certificado al correo registrado, confirme su correo electronico en nuestras oficinas",
                  "warning"
                );
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .then(function () {
              vue.showProgressCertificados = false;

              var link =
                "https://www.berlinave.com/BERLINUXERP/gettiquetesseleccionadosse.php?tiquetes=" +
                cadena +
                "&cedula=" +
                vue.buscarcertificado.documento +
                "&perinicial=" +
                vue.buscarcertificado.perInicial +
                "&perfinal=" +
                vue.buscarcertificado.perFinal +
                "&nit=" +
                vue.validarvacio(vue.buscarcertificado.nit) +
                "&empresa=" +
                vue.validarvacio(vue.buscarcertificado.empresa);
              window.open(link, "_blank");
            });
        }
      } else {
        alert("No ha seleccionado viajes");
      }
    },

    generar: function (tiq) {
      var link = "https://www.berlinave.com/getcertificado.php?tiquete=" + tiq;
      window.open(link, "_blank");
    },

    validarvacio: function (campo) {
      if (campo === "") {
        return "_";
      } else {
        return campo;
      }
    },
  },

  components: {
    NavbarMainSE,
    FooterSE,
  },
};
</script>

<style>
.titulocertificados p {
  color: #004d27;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  margin-top: 10px;
}

.parrafocertificados p {
  color: #004d27;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

.icondownload {
  font-size: 35px;
  color: inherit;
  width: 50px;
  height: 60px;
  line-height: 38px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin: 0 8px;
  opacity: 0.75;
}

.btn-berlinave {
  background-color: #004d27 !important;
  color: white !important;
}

.formorigendestino {
  margin-top: 10px;
}

.superior {
  margin-top: 10px;
}

.inferior {
  margin-bottom: 10px;
}

.alineacionizquierda {
  text-align: left;
}

.formBottomlarge {
  margin-bottom: 10px;
}

.formtoken {
  margin-bottom: 10px;
}
</style>
