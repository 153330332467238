<template>
  <div style="zoom: 80%; ">
    <div class="capsule-selection">
      <selection class="footer">
        <div class="social">
          <a href="#"
            ><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"
          /></a>
          <a href="#"
            ><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"
          /></a>
          <a href="#"
            ><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }"
          /></a>
          <a href="https://www.instagram.com/berlinave_"
            ><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }"
          /></a>
        </div>

        <ul class="list">
          <li>
            <router-link :to="{ name: 'Politicas' }" class="nav-link"
              >Contrato de transporte</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Politicas' }" class="nav-link"
              >Politica de protección de datos</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Politicas' }" class="nav-link"
              >Seguridad Vial</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Politicas' }" class="nav-link"
              >Terminos y condiciones</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Politicas' }" class="nav-link"
              >Políticas</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Actualiza' }" class="nav-link"
              >Actualiza tus datos</router-link
            >
          </li>
        </ul>
        <ul class="list">
          <li>
            <img src="@/assets/logovigilado2024medium.png" class="img-fluid" alt="..." />
          </li>
          <li>
  
          </li>
        </ul>
      </selection>
    </div>

    <div class="piedepagina">
      <!--
          <span
            ><a href="https://www.solincosta.com/">
              Sitio desarrollador por: www.solincosta.com para - CIA LIBERTADOR
              SA © 2021 Derechos reservados</a
            ></span
          > -->
      <ul class="list">
        <li><a href="https://www.tas.com.co/tasweb/">TAS</a></li>
        <li><a href="https://mail.berlinasdelfonce.com/">Correo Corporativo</a></li>
        <li><a href="https://plataforma.colfenixgps.co/">Colfenix GPS</a></li>
        <li><a href="http://iso.berlinasdelfonce.com/IsolucionCalidad">Isolucion</a></li>
        <li><a href="https://www.berlinave.com/BERLINUXERP/login.php">BERLINUXERP</a></li>
      </ul>
    </div>
  </div>
</template>

<script scoped>
export default {
  name: "Footer",
};
</script>

<style>
.footer {
  padding: 40px 0;
  /*#fff*/
}

.capsule-selection {
  margin-top: 20px;
  background-color: #004d27;
}

.footer .social {
  text-align: center;
  padding-top: 15px;

  color: #ffffff;
  /*#004D27-4b4c4d*/
}

.footer .social a {
  font-size: 45px;
  color: inherit;
  /*border: 1px solid #ccc;*/
  width: 50px;
  height: 60px;
  line-height: 38px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin: 0 8px;
  opacity: 0.75;
}

.footer .social a:hover {
  opacity: 0.9;
}

.footer ul {
  margin-top: 0;
  padding: 0;
  list-style: none;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
  text-align: center;
}

.footer ul li a {
  color: #ffffff;
  text-decoration: none;
  opacity: 0.8;
}

.footer ul li {
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 15px;
}

.footer ul li a:hover {
  opacity: 1;
}

.footer ul li img {
  width: 50%;

}

.footer .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 13px;
  background-color: #ffffff;
}

.piedepagina {
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  background-color: #097948;
}

.piedepagina ul {
  margin-top: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 0;
  text-align: center;
}

.piedepagina ul li {
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 0;
}

.piedepagina ul li a {
  color: #ffffff;
  text-decoration: none;
  opacity: 0.8;
}

</style>
