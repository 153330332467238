<template>
  <div>
    <NavbarMain />
    <img src="@/assets/contactanos1400.jpg" class="img-fluid" alt="..." />
    <div class="container" style="zoom: 80%">
      <div class="form-row">
        <div class="form-group col-md-12" style="text-align: center">
          <div class="titulo">
            <p>Atención al cliente</p>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="flex justify-content-center flex-wrap card-container">
          <Card style="width: 25em">
            <template #header>
              <img alt="user header" src="@/assets/PQRS.png" />
            </template>
            <template #title> Radicación de PQRS </template>
            <template #content>
              <p>
                Nuestro sistema de Peticiones, Quejas, Reclamos y Sugerencias
                (PQRS) es una herramienta que nos permite conocer las
                inquietudes y manifestaciones que tienen nuestros usuarios y
                tengamos la oportunidad de fortalecer nuestro servicio y seguir
                en el camino hacia la excelencia operativa!
              </p>
            </template>
            <template #footer>
              <Button
                icon="pi pi-plus"
                label="Radicar nueva"
                @click="nuevapqr"
                class="btn-berlinave"
              />
              <Button
                icon="pi pi-search"
                label="Consultar estado"
                severity="secondary"
                class="btn-berlinave"
                style="margin-left: 0.5em"
              />
            </template>
          </Card>
        </div>
      </div>
    </div>

    <Dialog
      header="Header"
      :visible="showmodalradicar"
      :modal="true"
      :style="{ width: '75vw' }"
      @update:visible="ocultarDialogRadicar"
    >
      <template #header>
        <center>
          <h5>Radicar solicitud PQRS</h5>
        </center>
      </template>

      <p>
        Conforme al Decreto 1377 de 2013, reglamentario de la Ley 1581 de 2012
        en materia de protección de datos personales, COMPAÑÍA LIBERTADOR S.A.
        solicita a todas las personas naturales registradas en nuestras bases de
        datos el cumplimiento de las obligaciones legales que permitan y
        autoricen la continuación del tratamiento de sus datos personales, en
        los términos previstos en la política de tratamiento de datos personales
        de COMPAÑÍA LIBERTADOR S.A. utilizadas con las finalidades de dar manejo
        de PQR´S
      </p>

      <br />

      <form>

        <div class="p-fluid grid formgrid">
          <div class="field col-3 md:col-6 sm:col-12">
            <Dropdown
              v-model="pqr.tipo_documento"
              :options="tiposDeDocumentos"
              optionLabel="detalle"
              placeholder="Seleccione tipo documento..."
            />
          </div>

          <div class="field col-3 md:col-6 sm:col-12">
            <InputText placeholder="N° de documento" v-model="pqr.documento" />
          </div>

          <div class="field col-6 md:col-12">
            <InputText
              placeholder="Nombre completo"
              v-model="pqr.nombrecompleto"
            />
          </div>

          <div class="field col-3 md:col-12">
            <Dropdown
              v-model="pqr.departamento"
              :options="departamentos"
              optionLabel="detalle"
              placeholder="Seleccione el departamento..."
              @change="consultarMunicipios($event)"
            />
          </div>

          <div class="field col-3 md:col-12">
            <Dropdown
              v-model="pqr.municipio"
              :options="municipios"
              optionLabel="detalle"
              placeholder="Seleccione el municipio..."
            />
          </div>

          <div class="field col-3 md:col-12">
            <Dropdown
              v-model="pqr.tipopqr"
              :options="tiposPqr"
              optionLabel="detalle"
              placeholder="Seleccione el tipo de PQR..."
            />
          </div>

          <div class="field col-3 md:col-3">
            <Dropdown
              v-model="pqr.lugarservicio"
              :options="lugaresYServicios"
              optionLabel="detalle"
              placeholder="Seleccione el Lugar o Servicio..."
            />
          </div>

          <div class="field col-3 md:col-3">
            <InputText placeholder="Telefono" v-model="pqr.telefono" />
          </div>

          <div class="field col-3 md:col-3">
            <InputText placeholder="Telefono 2" v-model="pqr.telefono2" />
          </div>

          <div class="field col-3 md:col-3">
            <InputText placeholder="Correo" v-model="pqr.correo" />
          </div>

          <div class="field col-3 md:col-3">
            <InputText placeholder="Dirección" v-model="pqr.direccion" />
          </div>

          <div class="field col-12 md:col-3">
            <Textarea
              v-model="pqr.pqr"
              rows="4"
              cols="30"
              placeholder="Escriba su PQR"
            />
          </div>

          <div class="field col-12 md:col-3">
            <input
              type="file"
              class="form-control-file"
              id="pqr_files"
              ref="pqr_files"
              @change="onFileChange"
              multiple
            />
          </div>

          <div v-show="showError" class="field col-12 md:col-3">
            <InlineMessage severity="error">{{ messageWAR }}</InlineMessage>
          </div>

          

        </div>

        <div v-show="showProgress">
          <ProgressBar mode="indeterminate" style="height: 0.5em" />
        </div>
      </form>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          v-show="pqr.wait"
          animationDuration=".5s"
        />

        <Button
          icon="pi pi-check"
          autofocus
          label="Radicar"
          :disabled="pqr.botondisabled"
          @click="enviarPQR"
        />
      </template>
    </Dialog>
    <!-- Modal Radicar-->

    <!-- Modal consultar-->
    <div
      class="modal fade"
      id="modal-consultar"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Consultar solicitud
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <input
                    class="form-control"
                    type="text"
                    v-model="searchpqr.documento"
                    id="view_pqr_documento"
                    placeholder="Consultar por documento"
                    @keyup.enter="buscarPqrXDocumento"
                    maxlength="10"
                  />
                </div>

                <div class="form-group col-md-6">
                  <input
                    class="form-control"
                    type="text"
                    v-model="searchpqr.radicado"
                    id="view_pqr_radicado"
                    placeholder="Consultar por Radicado"
                    @keyup.enter="buscarPqrXRadicado"
                    maxlength="30"
                  />
                </div>
              </div>

              <div v-show="showProgressView">
                <ProgressBar mode="indeterminate" style="height: 0.5em" />
              </div>

              <table class="table table-striped">
                <thead style="background-color: #004d27; color: white">
                  <tr>
                    <th scope="col">RADICADO</th>
                    <th scope="col">LUGAR</th>
                    <th scope="col">TIPO PQR</th>
                    <th scope="col">FECHA RAD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="p in pqrs" :key="p.pqr_radicado">
                    <td>{{ p.pqr_radicado }}</td>
                    <td>{{ p.lgpqr_detalle }}</td>
                    <td>{{ p.tppqr_detalle }}</td>
                    <td>{{ p.pqr_fecharadicacion }}</td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
//import InputText from "primevue/inputtext";
/*
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";
import Calendar from "primevue/calendar";
import CascadeSelect from "primevue/cascadeselect";
*/
const $ = require("jquery");
window.$ = $;
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      messageWAR: "",
      showError: false,
      showmodalradicar: false,
      showProgressRadicando: false,
      pqrs: [],
      spncombospqr: false,
      spnmunicipios: false,
      limpiarcampos: false,
      showProgress: false,
      showProgressView: false,
      tiposDeDocumentos: [],
      departamentos: [],
      municipios: [],
      tiposPqr: [],
      lugaresYServicios: [],
      selectedFiles: [],
      sizeSelectedFiles: 0,

      //TIPO DOC OBJ
      tipodocumento: {
        id: 0,
        detalle: "",
      },

      //PQR OBJ
      pqr: {
        tipo_documento: 0,
        documento: "",
        nombrecompleto: "",
        departamento: 0,
        municipio: 0,
        tipopqr: 0,
        lugarservicio: 0,
        telefono: "",
        telefono2: "",
        correo: "",
        direccion: "",
        pqr: "",
        wait: false,
        botondisabled: false,
      },

      searchpqr: {
        documento: "",
        radicado: "",
      },

      viewpqr: {
        documento: "",
        radicado: "",
      },
    };
  },

  created: function () {
    //this.$utilman.fn1();
    this.getTiposDeDocumentos();
    this.getDepartamentos();
    this.getTiposPQR();
    this.getLugaresServicios();
  },

  computed: {
    validarEnviarPQR() {
      var mensajeAlerta = "";
      if (this.pqr.tipo_documento === 0) {
        mensajeAlerta = "Debe seleccionar un tipo de documento de identidad!";
      } else {
        if (
          this.pqr.documento === "" ||
          !this.$utilidad.validarCedula(this.pqr.documento)
        ) {
          mensajeAlerta = "Debe escribir un documento de identidad válido!";
        } else {
          if (
            this.pqr.nombrecompleto === "" ||
            this.pqr.nombrecompleto.length < 10
          ) {
            mensajeAlerta = "Debe escribir un nombre válido!";
          } else {
            if (this.pqr.municipio === 0) {
              mensajeAlerta = "Debe seleccionar un municipio!";
            } else {
              if (this.pqr.tipopqr === 0) {
                mensajeAlerta = "Debe seleccionar un tipo de PQR!";
              } else {
                if (this.pqr.lugarservicio === 0) {
                  mensajeAlerta = "Debe seleccionar un lugar o servicio!";
                } else {
                  if (
                    this.pqr.telefono === "" ||
                    !this.$utilidad.validarTelefono(this.pqr.telefono)
                  ) {
                    mensajeAlerta = "Debe digitar un N° de teléfono válido!";
                  } else {
                    if (
                      this.pqr.correo === "" ||
                      !this.$utilidad.validarCorreo(this.pqr.correo) ||
                      this.pqr.correo.length > 30
                    ) {
                      mensajeAlerta =
                        "Debe digitar un correo electrónico válido!";
                    } else {
                      if (
                        this.pqr.pqr === "" ||
                        this.pqr.pqr.length < 50 ||
                        this.pqr.pqr.length > 1200
                      ) {
                        mensajeAlerta =
                          "Debe escribir su PQR, la PQR debe contener mínimo 50 caracteres y máximo 1200 caracteres!";
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (this.pqr.telefono2 !== "") {
        if (!this.$utilidad.validarTelefono(this.pqr.telefono2)) {
          mensajeAlerta = "Debe digitar un N° de teléfono válido!";
        }
      } else {
        if (this.pqr.direccion !== "") {
          if (this.pqr.direccion.length > 50) {
            mensajeAlerta = "La dirección debe tener máximo 50 caracteres!";
          }
        }
      }
      return mensajeAlerta;
    },
  },

  methods: {
    ocultarDialogRadicar: function (value) {
      if (!value) {
        this.showmodalradicar = false;
      }
    },

    nuevapqr: function () {
      this.showmodalradicar = true;
    },

    getTiposDeDocumentos: async function () {
      let vue = this;
      this.spncombospqr = true;
      await this.axios
        .get("model/pqr.php?dato=gettiposdedocumentos")
        .then(function (response) {
          vue.tiposDeDocumentos = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.spncombospqr = false;
        });
    },

    getDepartamentos: async function () {
      this.spncombospqr = true;
      let vue = this;
      await this.axios
        .get("model/pqr.php?dato=getdepartamentos")
        .then(function (response) {
          vue.departamentos = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.spncombospqr = false;
        });
    },

    consultarMunicipios: async function (event) {
      var id_dpto = event.value.id;
      this.showProgress = true;
      let vue = this;

      await this.axios
        .get("model/pqr.php?dato=getmunicipios&pqr_dpto=" + id_dpto)
        .then(function (response) {
          vue.municipios = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgress = false;
        });
    },

    getTiposPQR: async function () {
      this.spncombospqr = true;
      let vue = this;
      await this.axios
        .get("model/pqr.php?dato=gettiposdepqr")
        .then(function (response) {
          vue.tiposPqr = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.spncombospqr = false;
        });
    },

    getLugaresServicios: async function () {
      this.spncombospqr = true;
      let vue = this;
      await this.axios
        .get("model/pqr.php?dato=getlugaresdepqr")
        .then(function (response) {
          vue.lugaresYServicios = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.spncombospqr = false;
        });
    },

    radicar: function () {
      alert("HOLA MUNDO");
    },

    enviarPQR: async function () {
      try {
        var mensaje = this.validarEnviarPQR;

        if (mensaje === "") {
          this.showProgress = true;

          let formData = new FormData();
          formData.append("file", this.selectedFile);
          var pesomax = this.$utilidad.getPesoMaxFiles();
          var basemegas = this.$utilidad.getBaseMegas();
          var validador = false;

          for (var i = 0; i < this.sizeSelectedFiles; i++) {
            var temp = Math.trunc(this.selectedFiles[i].size / basemegas);
            if (temp > pesomax) {
              validador = true;
            }
            formData.append("file[]", this.selectedFiles[i]);
          }
          //JSON.stringify(Object.fromEntries(formData))
          formData.append("pqr_tipodocumento", this.pqr.tipo_documento.id);
          formData.append("pqr_documento", this.pqr.documento);
          formData.append("pqr_nombre", this.pqr.nombrecompleto);
          formData.append("pqr_municipio", this.pqr.municipio.id);
          formData.append("pqr_tipopqr", this.pqr.tipopqr.id);
          formData.append("pqr_lugarservicio", this.pqr.lugarservicio.id);
          formData.append("pqr_telefono", this.pqr.telefono);
          formData.append("pqr_telefono2", this.pqr.telefono2);
          formData.append("pqr_correo", this.pqr.correo);
          formData.append("pqr_direccion", this.pqr.direccion);
          formData.append("pqr_pqr", this.pqr.pqr);

          let self = this;
          if(!validador){
          await self
            .axios({
              method: "post",
              url: "model/pqr.php?dato=insertarpqrweb",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (self.$utilidad.confirmInsert(response)) {
                var mensajeEmail = self.$utilidad.confirmSendEmail(response);
                self.showmodalradicar = false;
                self.messageWAR = "";
                self.showError = false;

                self.$swal.fire(
                  "Transacción Exitosa!",
                  "SE HA GUARDADO CORRECTAMENTE " + mensajeEmail,
                  "success"
                );

                self.cleanFieldsNewPQR();
                
                //$("#modal-radicar").modal("hide");
              } else if (response.data.includes("HUBO UN ERROR DE EXCEPCIÓN")) {
                self.$swal.fire("Error!", response.data, "error");
              } else {
                self.$swal.fire("Error!", "Error desconocido", "error");
                console.log(response.data);
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .finally(() => {
              self.showProgress = false;
            });
          } else {
            this.messageWAR = "NO SE PUDO GUARDAR LA INFORMACIÓN, UNO DE LOS ARCHIVOS TIENE UN PESO SUPERIOR A 1.5MB, REDUZCA EL TAMAÑO DE SUS ARCHIVOS ADJUNTOS";
            this.showError = true;
            this.showProgress = false;
          }
        } else {
          this.messageWAR = mensaje;
          this.showError = true;
        }
      } catch (e) {
        console.log("Exception Javascript: " + e);
      }
    },

    buscarPqrXDocumento: async function () {
      var datosEnviar = {
        pqr_cedula_consulta: this.searchpqr.documento,
      };
      try {
        this.showProgressView = true;
        let self = this;
        await self.axios
          .post(
            "model/pqr.php?dato=gettablapqrsxcedulaapi",
            JSON.stringify(datosEnviar)
          )
          .then(function (response) {
            console.log(response.data);
            self.pqrs = response.data;
            //vue.tiposDeDocumentos = response.data;
          })
          .catch((error) => {
            console.log("ERROR: " + error);
            console.log("ERROR responseText: " + error.responseText);
            console.log("ERROR STATUS: " + error.status);
            console.log("ERROR TEXT_STATUS: " + error.textStatus);
          })
          .finally(() => {
            console.log("ALLWAYS...");
            this.showProgressView = false;
          });
      } catch (e) {
        console.log("Exception Javascript: " + e);
      }
    },

    buscarPqrXRadicado: async function () {
      var datosEnviar = {
        pqr_radicado_consulta: this.searchpqr.radicado,
      };
      try {
        this.showProgressView = true;
        let self = this;
        await self.axios
          .post(
            "model/pqr.php?dato=gettablapqrsxradicadoapi",
            JSON.stringify(datosEnviar)
          )
          .then(function (response) {
            console.log(response.data);
            self.pqrs = response.data;
            //vue.tiposDeDocumentos = response.data;
          })
          .catch((error) => {
            console.log("ERROR: " + error);
            console.log("ERROR responseText: " + error.responseText);
            console.log("ERROR STATUS: " + error.status);
            console.log("ERROR TEXT_STATUS: " + error.textStatus);
          })
          .finally(() => {
            console.log("ALLWAYS...");
            this.showProgressView = false;
          });
      } catch (e) {
        console.log("Exception Javascript: " + e);
      }
    },

    consultarpqr: function (radicado) {
      alert(radicado);
    },

    onFileChange(event) {
      //console.log(event);
      this.sizeSelectedFiles = event.target.files.length;
      this.selectedFiles = event.target.files;
    },

    cleanFieldsNewPQR() {
      this.pqr = {
        tipo_documento: 0,
        documento: "",
        nombrecompleto: "",
        departamento: 0,
        municipio: 0,
        tipopqr: 0,
        lugarservicio: 0,
        telefono: "",
        telefono2: "",
        correo: "",
        direccion: "",
        pqr: "",
        wait: false,
        botondisabled: false,
      };

      this.sizeSelectedFiles = 0;
      this.selectedFiles = [];
    
    },

  },

  components: {
    NavbarMain,
    Footer,
  },
};
</script>

<style>
.mensaje-habbeas-data {
  margin-bottom: 20px;
  font-size: 13px;
}

.titulo p {
  color: #004d27;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  margin-top: 10px;
}

.btn-berlinave {
  background-color: #004d27 !important;
  border-color: #004d27 !important;
  color: white !important;
}
</style>
