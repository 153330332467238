<template>
  <div>
    <NavbarMain />

    <div class="container">
      <div class="form-row">
        <div class="form-group col-md-12" style="text-align: center">
          <div class="titulocertificados">
            <p>
              Terminos y condiciones promoción ida y regreso
            </p>
          </div>
        </div>
      </div>

      <img src="@/assets/CONDICIONES_IDA_REGRESO.jpg" class="img-fluid" alt="..." />
    </div>
    <Footer />
  </div>
</template>

<script>

import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {

    };
  },

  created: function() {

  },

  computed: {},

  methods: {

  },

  components: {
    NavbarMain,
    Footer,
  },
};
</script>

<style>
.titulocertificados p {
  color: #004d27;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  margin-top: 10px;
}

.parrafocertificados p {
  color: #004d27;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}


.icondownload {
  font-size: 35px;
  color: inherit;
  width: 50px;
  height: 60px;
  line-height: 38px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin: 0 8px;
  opacity: 0.75;
}

.btn-berlinave {
  background-color: #004d27 !important;
  color: white !important;
}
</style>
