<template>
  <div>
    <NavbarMain />
    <div class="container-fluid">
      <!-- 404 Error Text -->
      <div class="text-center">
          <img src="@/assets/404.png" class="img-fluid" alt="..." />
        <p class="lead text-gray-800 mb-5">Página no encontrada</p>
        <a href="/">&larr; Regresar al sitio principal</a>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
//import Dropdown from "primevue/dropdown";

//import InputText from "primevue/inputtext";
/*
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";
import Calendar from "primevue/calendar";
import CascadeSelect from "primevue/cascadeselect";
*/
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {};
  },

  created: function() {},

  computed: {},

  methods: {},

  components: {
    NavbarMain,
    Footer,
  },
};
</script>

<style></style>
