<template>
  <div>
    <div class="header">
      <img src="@/assets/colibertadorblanco.png" class="logo_img" />
      <button class="menu-toggle" @click="toggleMenu">
        <i class="fa-solid fa-bars"></i>
      </button>
      <nav class="menu" :class="{ menu_visible: isMenuVisible }">
        <li :class="{ active: $route.name === 'Inicio' }">
          <router-link :to="{ name: 'Inicio' }" class="nav-link"
            >Inicio</router-link
          >
        </li>
        <li :class="{ active: $route.name === 'FormPQR' }">
          <router-link :to="{ name: 'FormPQR' }" class="nav-link"
            >PQR</router-link
          >
        </li>
        <li :class="{ active: $route.name === 'Novedades' }">
          <router-link :to="{ name: 'Novedades' }" class="nav-link"
            >Novedades</router-link
          >
        </li>
        <li :class="{ active: $route.name === 'PuntosVenta' }">
          <router-link :to="{ name: 'PuntosVenta' }" class="nav-link"
            >Puntos de venta</router-link
          >
        </li>
        <li :class="{ active: $route.name === 'Trabaja' }">
          <router-link :to="{ name: 'Trabaja' }" class="nav-link"
            >Trabaja con Nosotros</router-link
          >
        </li>
        <li :class="{ active: $route.name === 'Certificado' }">
          <router-link :to="{ name: 'Certificado' }" class="nav-link"
            >Certificado de Viaje</router-link
          >
        </li>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarMain",

  data() {
    return {
      isMenuVisible: false,
    };
  },

  methods: {
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
  },

  created: function () {
    // addEventListener("DOMContentLoaded", () => {
    //   const btn_menu = document.querySelector(".btn_menu");
    //   if (btn_menu) {
    //     btn_menu.addEventListener("click", () => {
    //       const menu_items = document.querySelector(".menu_items");
    //       menu_items.classList.toggle("show");
    //     });
    //   }
    // });
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  /* background-color: #f4f4f4; */
}

/* Only style */
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 5rem;
  background-color: var(--green-blnv);
  opacity: 0.9;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  color: #fff;
  font-size: 1rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-bottom: 1px solid #fff;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.menu {
  display: flex;
  list-style: none;
  width: 75%;
  height: 5rem;
  justify-content: center;
  font-size: 1.3rem;
}

.logo_img {
  margin: 1.5rem;
  width: 13rem;
  height: 2rem;
}

.logo_img:hover {
  scale: 1.1;
  cursor: pointer;
}

.menu_items {
  display: flex;
  list-style: none;
}

.menu li {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  cursor: pointer;
  padding: 0 20px;
  /* transition: all 0.2s ease 0s; */
  transition: 0.3;
}

.menu li:hover {
  background-image: linear-gradient(to bottom, #f4f4f4, white);
  color: var(--green-blnv);
  font-weight: bold;
  border-bottom: 3px var(--green-blnv) solid;
  opacity: 0.6;
}

/* .menu_items li a {
  padding: 7px 13px;
  text-decoration: none;
  font-size: 18px;
  display: block;
  color: #fff;
} */

.menu-toggle {
  color: #fff;
  display: none;
  background: none;
  border: none;
  font-size: 1.8rem;
}

.menu-toggle:hover {
  scale: 1.2;
}

.menu_items li:hover,
li.active {
  background-color: rgba(0, 0, 0, 0.3);
  /* transition: 0.4s; */
}

/* Responsive */

@media screen and (max-width: 768px) {
  .header {
    justify-content: space-between;
    padding: 0 20px;
  }

  .menu {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 20px;
    /* background-color: #2c3e50; */
    background-image: linear-gradient(to bottom, var(--green-blnv), #96d032);
    position: fixed;
    left: 0;
    top: 60px;
    width: 100%;
    padding: 20px 0;
    height: calc(100% - 60px);
    /* height: 50%; */
    overflow-y: auto;
    left: 100%;
    transition: 0.3s;
    
    font-family: "Comic Neue", cursive;
    font-size: 1.5rem;
    border-top: 2px solid #fff;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.6);
  }

  .menu li:hover {
    background: none;
    color: #2f5f02;
    font-weight: bold;
    border-bottom: none;
    scale: 1.2;
    /* opacity: 0.5; */
  }

  .menu-toggle {
    display: block;
    font-size: 20px;
    cursor: pointer;
  }

  .menu_visible {
    left: 0;
  }

  .menu .op-menu:hover > .list-menu {
    display: none;
  }

  .logo_img {
    width: 10rem;
  }
}

/* @media screen and (max-width: 858px) {
  .menu {
    height: 55px;
  }

  .btn_menu {
    display: inline-flex;
  }

  .menu_items {
       z-index: 5;
    position: fixed;
    width: 100vw;
    height: calc(100% - 65px);
    background: #004d27;


    opacity: 0.8;
    top: -100vh;
    text-align: center;
    transition: all 0.4s;
    flex-direction: column;
  }

  .menu_items li {
    margin: 30px 0 0 0;
    line-height: 30px;
  }

  .menu_items li:hover {
    background: none;
  }

  .menu_items li a {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
  }

  .menu_items li a:hover {
    color: #c8ff00;
  }

  .logo {
    font-size: 25px;
    padding-left: 35px;
  }
}

@media screen and (max-width: 858px) and (orientation: landscape) {
  .menu_items li {
    margin: 5px 0 0 0;
  }

  .menu_items {
    overflow: scroll;
    height: calc(100% - 65px);
  }
} */

.menu_corp {
  background-color: #004d27;
  height: 20px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: right;
  border-bottom: 1px solid #fff;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.6);
}

.menu_items_corp {
  display: flex;
  list-style: none;
  margin-right: 30px;
}

.menu_items_corp li a {
  padding: 1px 13px;
  text-decoration: none;
  font-size: 12px;
  display: block;

  color: #fff;
}
</style>
