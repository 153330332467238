<template>
  <div class="slider">
      <div class="slider__controls">
          <a @click="moveSlider(n)"
          href="" v-for="n in slides.length" :key="n" class="slider__controls__link">{{n}}</a>
      </div>
    <div
      class="slider__content"
      v-for="(image, index) in slides"
      :key="index"
      :style="`background-image:url(${image})`"
    ></div>
  </div>
</template>

<script>
export default {
  name: "SliderCustom",

  data() {
    return {
      slides: [
        "https://deportesjuegos.com/wp-content/uploads/2021/05/1622241412_Genshin-Impact-Kazuha-banner-4-estrellas-personajes-y-fecha-de.jpg",
        "https://www.zonammorpg.com/wp-content/uploads/2021/06/genshin16verano.jpg",
        "https://debmedia.com/blog/wp-content/uploads/2020/03/Atenci%C3%B3n-al-Cliente-en-Salud-banner.jpg",
      ],
    };
  },

  created: function() {},

  mounted: function() {
      this.slider = this.$refs.slider;
      this.height = this.slider.offsetHeight;
  },

  methods: {
    moveSlider: function(n) {
        this.slider.scroll({
            top: this.height * n,
            behavior: 'smooth'
        })
    },
  },
};
</script>

<style>
.slider {
  position: relative;
  height: 100vh;
  right: 0;
  top: 0;

  overflow: scroll;
  background-color: #ccc;
  scroll-snap-type: y mandatory;
}

.slider__controls{
    position: fixed;
    right: .5rem;
    top: 0;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.slider__controls__link{
    width: 3rem;
    height: 3rem;
    background-color: rgba(255, 255, 255, .8);
    color: black;
    border-radius: 58%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    margin: 1rem 0;
}

.slider__content {
  height: 100vh;
  background-position: center;
  background-size: cover;
  scroll-snap-align: center;
}

.btn-berlinave {
  background-color: #004d27 !important;
  color: white !important;
}
</style>
