<template>
  <div>
    <NavbarMain />
    <img
      :src="require(`@/assets/puntosdeventa.jpg`)"
      class="img-fluid"
      alt="..."
    />
    <div class="container" style="zoom: 80%; ">
      <div class="subcontainerpuntosventa">
        <div class="row row-cols-1 row-cols-md-2">
          <div class="card">
            <img
              src="@/assets/psantamarta.jpg"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">Santa Marta</h5>
              <p class="card-text">
                Calle 41 No. 31-17 Tel. 3187022453 - 3157167909 De 04:30 am -
                08:00 pm Troncal del caribe, Santa Marta, Magdalena
              </p>
            </div>
          </div>
          <div class="card">
            <img
              :src="require(`@/assets/pbarranquilla.jpg`)"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">Soledad - Atlántico</h5>
              <p class="card-text">
                Carrera 14 Esquina Calle 62 Tel. 315 7167911 De 04:30 am - 08:00
                pm Soledad, Atlantico
              </p>
            </div>
          </div>
          <div class="card">
            <img
              :src="require(`@/assets/pbarranquilla.jpg`)"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">Simón Bolivar - Barranquilla</h5>
              <p class="card-text">
                Calle 19 No. 4b-15 Tel. 318 3728015 De 05:00 am - 07:30
                pm Simón Bolivar - Barranquilla
              </p>
            </div>
          </div>
          <div class="card">
            <img
              :src="require(`@/assets/pcartagena.jpg`)"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">Cartagena</h5>
              <p class="card-text">
                Carretera la Coordialidad # 24-236 Tel. 318 6454545 De 04:30 am
                - 08:00 pm La Cordialidad, Cartagena, Bolivar
              </p>
            </div>
          </div>

          <div class="card">
            <img
              :src="require(`@/assets/pvalledupar.jpg`)"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">Valledupar</h5>

              <p class="card-text">
                Carrera 7a 44-126  Tel. 318 7174160 De 7:00 am - 02:00 pm
              </p>
            </div>
          </div>
          <div class="card">
            <img
              :src="require(`@/assets/cienaga1400.jpg`)"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">Cienaga</h5>

              <p class="card-text">
                Calle 17. N° 21-01 Tel. 316 5278719 De 05:00 am - 07:00 pm
              </p>
            </div>
          </div>
          <div class="card">
            <img
              :src="require(`@/assets/fundeque.jpg`)"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">Fundación</h5>

              <p class="card-text">
                Calle 2A N° 7-90 Tel. 315 7178009 De 04:30 am - 08:00 pm
              </p>
            </div>
          </div>
          <div class="card">
            <img
              :src="require(`@/assets/aracataca1200.jpg`)"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">Aracataca</h5>

              <p class="card-text">
                Calle 01. No 1E-65 Tel. 318 2061651 De 04:30 am - 06:00 pm
              </p>
            </div>
          </div>
          <div class="card">
            <img
              :src="require(`@/assets/bosconia490.jpeg`)"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">Bosconia</h5>

              <p class="card-text">
                Calle 18 No 18-43 Tel. 318 7174160 De 7:00 am - 02:00 pm
              </p>
            </div>
          </div>
          <div class="card">
            <img
              :src="require(`@/assets/riomagdalena.jpg`)"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">El Banco</h5>

              <p class="card-text">
                Carrera 19 No. 9a Tel. 318 7174160 De 7:00 am - 02:00 pm
              </p>
            </div>
          </div>
          <div class="card">
            <img
              :src="require(`@/assets/santaana500.jpg`)"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title">Santa Ana</h5>

              <p class="card-text">
                Kra. 8 Calle 11-35 Tel.3174412898 De 5:00 am - 02:00 pm
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
//import Dropdown from "primevue/dropdown";

//import InputText from "primevue/inputtext";
/*
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";
import Calendar from "primevue/calendar";
import CascadeSelect from "primevue/cascadeselect";
*/
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      showProgressRutas: false,
    };
  },

  created: function() {
  },

  computed: {},

  methods: {},

  components: {
    NavbarMain,
    Footer,
  },
};
</script>

<style>
.titulopuntos p {
  color: #004d27;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}


.subcontainerpuntosventa {
  margin-top: 30px;
}


.btn-berlinave {
  background-color: #004d27 !important;
  color: white !important;
}
</style>
