<template>
  <div>
    <NavbarMain />
    <img src="@/assets/conductor-banner-1400.png" class="img-fluid" alt="..." />

    <div class="contenedor-trabaja mt-4">
      <h3 class="tittle-bln">¿Quieres ser parte de Compañía Libertador?</h3>
      <form>
        <div class="p-fluid grid formgrid">
          <div class="field col-12 md:col-12">
            <p>
              Apreciado conductor te invitamos a unirte al equipo de Compañía
              Libertador SA. Nos gustaría conocerte, envíanos tu hoja de vida y
              en caso de tener una vacante te contactaremos, diligencia el
              formulario con los campos de contacto requeridos, ten en cuenta
              que su archivo adjunto no debe superar los (2MB) o (2000KB), de
              superarlos comprima su archivo por debajo del peso indicado
            </p>
          </div>

          <div class="field col-12 md:col-12">
            <InputText
              placeholder="Nombre completo"
              v-model="hoja.nombrecompleto"
            />
          </div>

          <div class="field col-12 md:col-12">
            <InputText placeholder="Telefono" v-model="hoja.telefono" />
          </div>

          <div class="field col-12 md:col-12">
            <InputText placeholder="Correo" v-model="hoja.correo" />
          </div>

          <div class="field col-12 md:col-12">
            <input
              type="file"
              class="form-control-file"
              id="pqr_files"
              ref="pqr_files"
              @change="onFileChange"
              multiple
            />
          </div>

          <div class="check-acepto mb-3">
            <InputSwitch
              inputId="switch1"
              style="zoom: 70%"
              class="mr-2"
              v-model="hoja.acepto"
            />
            <label for="switch1"
              >Al activar esta casilla admito que he leído y aceptado la:
              <a
                href="https://www.berlinave.com/ProteccinDatosPersonales.pdf"
                target="_blank"
                >Política de protección de datos Personales de Cia Libertador SA
                *</a
              ></label
            >
          </div>

          <div class="field col-12 md:col-12">
            <Button
              icon="pi pi-check"
              autofocus
              label="Enviar"
              :disabled="hoja.botondisabled"
              @click="enviarHV"
            />
          </div>

          <div v-show="showError" class="field col-12 md:col-3">
            <InlineMessage severity="error">{{ messageWAR }}</InlineMessage>
          </div>
        </div>

        <div v-show="showProgress">
          <ProgressBar mode="indeterminate" style="height: 0.5em" />
        </div>
      </form>
    </div>

    <Footer />
  </div>
</template>

<script>
//import InputText from "primevue/inputtext";
/*
  import Dropdown from "primevue/dropdown";
  import AutoComplete from "primevue/autocomplete";
  import Calendar from "primevue/calendar";
  import CascadeSelect from "primevue/cascadeselect";
  */
const $ = require("jquery");
window.$ = $;
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      hoja: {
        nombrecompleto: "",
        telefono: "",
        correo: "",
        acepto: false,
        wait: false,
        botondisabled: false,
      },

      messageWAR: "",
      showError: false,

      limpiarcampos: false,
      showProgress: false,
      showProgressView: false,

      selectedFiles: [],
      sizeSelectedFiles: 0,
    };
  },

  created: function () {
    //this.$utilman.fn1();
  },

  computed: {
    validarEnviarHV() {
      var mensajeAlerta = "";
      if (this.hoja.nombrecompleto === "") {
        mensajeAlerta = "Debe digitar un nombre válido!";
      } else if (this.hoja.telefono === "" || this.hoja.telefono.length < 10) {
        mensajeAlerta =
          "Debe digitar un teléfono válido, verífique la cantidad de dígitos!";
      } else if (this.hoja.acepto === false) {
        mensajeAlerta = "Debe aceptar la política de protección de datos!";
      }

      return mensajeAlerta;
    },
  },

  methods: {
    enviarHV: async function () {
      try {
        var mensaje = this.validarEnviarHV;

        if (mensaje === "") {
          this.showProgress = true;

          let formData = new FormData();
          formData.append("file", this.selectedFile);
          var pesomax = this.$utilidad.getPesoMaxFiles();
          var basemegas = this.$utilidad.getBaseMegas();
          var validador = false;

          if (this.sizeSelectedFiles == 0) {
            this.messageWAR =
              "SE DEBE ADJUNTAR LA HOJA DE VIDA OBLIGATORIAMENTE, EL ARCHIVO DEBE CONTENER UN PESO INFERIOR A 2MB";
            this.showError = true;
            this.showProgress = false;
            return;
          }

          for (var i = 0; i < this.sizeSelectedFiles; i++) {
            var temp = Math.trunc(this.selectedFiles[i].size / basemegas);
            if (temp > pesomax) {
              validador = true;
            }
            formData.append("file[]", this.selectedFiles[i]);
          }
          //JSON.stringify(Object.fromEntries(formData))
          formData.append("hoja_nombre", this.hoja.nombrecompleto);
          formData.append("hoja_telefono", this.hoja.telefono);
          formData.append("hoja_correo", this.hoja.correo);

          this.hoja.botondisabled = true;

          let self = this;
          if (!validador) {

            
            await self
              .axios({
                method: "post",
                url: "pqr.php?dato=insertarhvweb",
                data: formData,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
  
                if (self.$utilidad.confirmInsert(response)) {
                  
                  var mensajeEmail =
                    self.$utilidad.confirmSendEmailHV(response);

                  self.messageWAR = "";
                  self.showError = false;
                  self.hoja.botondisabled = false;

                  self.selectedFiles = [];
                  self.sizeSelectedFiles = 0;

                  self.$swal.fire(
                    "Transacción Exitosa!",
                    "SE HA ENVIADO SU HOJA DE VIDA CORRECTAMENTE, " +
                      mensajeEmail,
                    "success"
                  );

                  self.cleanFieldsNewHV();

                  //$("#modal-radicar").modal("hide");
                } else if (
                  response.data.includes("HUBO UN ERROR DE EXCEPCIÓN")
                ) {
                  self.$swal.fire("Error!", response.data, "error");
                } else {
                  self.$swal.fire("Error!", "Error desconocido", "error");
                  console.log(response.data);
                }
              })
              .catch((error) => {
                this.$utilidad.printConsole(error);
              })
              .finally(() => {
                self.showProgress = false;
                self.hoja.botondisabled = false;
              });
          } else {
            this.messageWAR =
              "NO SE PUEDE ADJUNTAR LA HOJA DE VIDA, EL ARCHIVO TIENE UN PESO SUPERIOR A 2MB, REDUZCA EL TAMAÑO DE SU ARCHIVO ADJUNTO";
            this.showError = true;
            this.showProgress = false;
          }
        } else {
          this.messageWAR = mensaje;
          this.showError = true;
        }
      } catch (e) {
        console.log("Exception Javascript: " + e);
      }
    },

    onFileChange(event) {
      //console.log(event);
      this.sizeSelectedFiles = event.target.files.length;
      this.selectedFiles = event.target.files;
    },

    cleanFieldsNewHV() {
      (this.hoja = {
        nombrecompleto: "",
        telefono: "",
        correo: "",
        acepto: false,
        wait: false,
        botondisabled: false,
      }),
        (this.sizeSelectedFiles = 0);
      this.selectedFiles = [];
    },
  },

  components: {
    NavbarMain,
    Footer,
  },
};
</script>

<style scoped>
.img-main {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center center;
}
.contenedor-trabaja {
  width: 70%;
  margin: auto;
}

.check-acepto {
  display: flex;
  justify-content: center;
}

.console {
  border: 2px red solid;
}

.mensaje-habbeas-data {
  margin-bottom: 20px;
  font-size: 13px;
}

.titulo p {
  color: #004d27;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  margin-top: 10px;
}

.btn-berlinave {
  background-color: #004d27 !important;
  border-color: #004d27 !important;
  color: white !important;
}
</style>
