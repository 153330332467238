<template>
  <div class="home">
     <NavbarMain />
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarMain from '@/components/NavbarMain.vue'
export default {
  name: 'AtencionAlCliente',

    components: {
    NavbarMain
  },
}
</script>