<template>
  <div>
    <NavbarMain />

    <div class="contenedor-trabaja mt-4">
      <h3 class="tittle-bln">Calcular de recaudo pasajeros sueltos x viaje</h3>
      <form>
        <div class="p-fluid grid formgrid">
          <div class="field col-12 md:col-12">
            <p>
              Digita el número del viaje que quieres consultar, ten en cuenta
              que solo se podrán consultar viajes de hoy mismo, si el viaje se
              realizó en días anteriores no aparecerá la información del viaje.
            </p>
            <p>
              Si algún puesto de control no reportó la información de la
              cantidad de pasajeros el calculo del recaudo no será exacto.
            </p>
          </div>

          <div class="field col-6 md:col-12">
            <InputText
              v-model="viaje"
              placeholder="Digite N° de viaje"
              class="form-control"
              id="get_recaudo_x_viaje"
              autocomplete="off"
            />
          </div>

          <div class="field col-3 md:col-12">
            <Dropdown
              v-model="categoria"
              :options="categorias"
              optionLabel="name"
              placeholder="Seleccionar categoría"
            />
          </div>

          <div class="field col-3 md:col-12">
            <Button
              icon="pi pi-check"
              autofocus
              label="Consultar"
              @click="getValorRecaudar"
            />
          </div>

          <div v-show="showError" class="field col-12 md:col-3">
            <InlineMessage severity="warn">{{ messageWAR }}</InlineMessage>
          </div>
        </div>

        <div v-show="showProgress">
          <ProgressBar mode="indeterminate" style="height: 0.5em" />
        </div>
      </form>

      <div class="card">
        <Timeline
          :value="controles"
          align="alternate"
          class="customized-timeline"
        >
          <template #marker="slotProps">
            <span
              class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
              :style="{ backgroundColor: slotProps.item.color }"
            >
              <i :class="slotProps.item.icon"></i>
            </span>
          </template>
          <template #content="slotProps">
            <Card class="mt-3">
              <template #title>
                <div v-if="slotProps.item.terminalOrigen">
                  {{ slotProps.item.nombreTerminalOrigen }}
                </div>
                <div
                  v-else-if="
                    !slotProps.item.terminalOrigen &&
                    !slotProps.item.terminalDestino
                  "
                >
                  {{ slotProps.item.pc_nombre }}
                </div>
                <div v-else>
                  {{ slotProps.item.Nombre2 }}
                </div>
              </template>
              <template #subtitle>
                <div
                  v-if="
                    slotProps.item.controlado && !slotProps.item.terminalDestino
                  "
                >
                  {{ slotProps.item.date }}
                </div>
              </template>
              <template #content>
                <h4
                  v-if="
                    slotProps.item.controlado && !slotProps.item.terminalDestino
                  "
                >
                  <i class="pi pi-users" style="font-size: 2rem"></i>
                  {{ slotProps.item.cantidad }}
                </h4>
                <h4
                  v-else-if="
                    slotProps.item.controlado && slotProps.item.terminalDestino
                  "
                >
                  Sueltos:
                  {{ slotProps.item.cantidad }}
                  Recaudo:
                  <Badge value="" size="xlarge" severity="success">{{
                    $utilidad.formatCurrency(slotProps.item.recaudo)
                  }}</Badge>
                </h4>
                <h5 v-else>No se registró control de pasajeros</h5>
                <Button
                  v-if="
                    slotProps.item.controlado &&
                    !slotProps.item.terminalOrigen &&
                    !slotProps.item.terminalDestino
                  "
                  label="Ver control"
                  text
                ></Button>
              </template>
            </Card>
          </template>
        </Timeline>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
//import InputText from "primevue/inputtext";
/*
  import Dropdown from "primevue/dropdown";
  import AutoComplete from "primevue/autocomplete";
  import Calendar from "primevue/calendar";
  import CascadeSelect from "primevue/cascadeselect";
  */
const $ = require("jquery");
window.$ = $;
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      viaje: "",
      categoria: 0,
      categorias: [
        { name: "COLIBERTADOR", code: 1 },
        { name: "BERLINAVE", code: 2 },
      ],

      messageWAR: "",
      showError: false,

      showProgress: false,
      showProgressView: false,

      controles: [],
    };
  },

  created: function () {},

  methods: {
    getValorRecaudar: async function () {
      this.messageWAR = "";
      this.showError = false;

      if (this.viaje == "") {
        this.messageWAR = "Debe digitar un N° de viaje válido";
        this.showError = true;
        return;
      }

      if(this.categoria == 0){
        this.messageWAR = "Debe seleccionar una categoría obligatoriamente";
        this.showError = true;
        return;
      }

      var datosEnviar = {
        viaje: this.viaje,
        categoria: this.categoria.code,
      };

      this.showProgress = true;
      let vue = this;
      await this.axios
        .post(
          "api/control.php?dato=getrecaudoxviaje",
          JSON.stringify(datosEnviar)
        )
        .then(function (response) {
          vue.controles = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgress = false;
          if (vue.controles.length === 0) {
            vue.messageWAR = "N° de viaje inválido o el viaje no es de hoy";
            vue.showError = true;
          }
        });
    },
  },

  components: {
    NavbarMain,
    Footer,
  },
};
</script>

<style scoped>
.img-main {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center center;
}
.contenedor-trabaja {
  width: 70%;
  margin: auto;
}

.check-acepto {
  display: flex;
  justify-content: center;
}

.console {
  border: 2px red solid;
}

.mensaje-habbeas-data {
  margin-bottom: 20px;
  font-size: 13px;
}

.titulo p {
  color: #004d27;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  margin-top: 10px;
}

.btn-berlinave {
  background-color: #004d27 !important;
  border-color: #004d27 !important;
  color: white !important;
}
</style>
