<template>
  <div >
    <nav class="menu">
      <img src="@/assets/logoberlinastur.png" class="logo_img" />
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavbarMain",

  created: function() {
    addEventListener("DOMContentLoaded", () => {
      const btn_menu = document.querySelector(".btn_menu");

      if (btn_menu) {
        btn_menu.addEventListener("click", () => {
          const menu_items = document.querySelector(".menu_items");
          menu_items.classList.toggle("show");
        });
      }
    });
  },
};
</script>

<style scoped>
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  /* background-color: #f4f4f4; */
}

/* Only style */

.menu {
  background-color: #FFFFFF;
  height: 75px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.6);
}

.logo {
  color: #fff;
  font-size: 35px;
  padding: 10px 80px;
  font-weight: bold;
}

.logo_img {
  width: 15%;
  margin-left: 200px;
}

.menu_items {
  display: flex;
  list-style: none;
  margin-right: 30px;
}

.menu_items li {
  border-radius: 3px;
  margin: 0 5px;
}

.menu_items li a {
  padding: 7px 13px;
  text-decoration: none;
  font-size: 18px;
  display: block;
  /*text-transform: uppercase;*/
  color: #fff;
}

.menu_items li:hover,
li.active {
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.4s;
}

.btn_menu {
  margin-right: 30px;
  font-size: 25px;
  color: #fff;
  cursor: pointer;
  display: none;
}

ul.show {
  top: 65px;
}

/* Responsive */

@media screen and (max-width: 952px) {
  .logo {
    font-size: 30px;
    padding-left: 35px;
  }

  .logo_img {
    width: 30%;
    margin-left: 50px;
  }

  .menu_items li a {
    font-size: 16px;
  }
}

@media screen and (max-width: 858px) {
  .menu {
    height: 55px;
  }

  .btn_menu {
    display: inline-flex;
  }

  .menu_items {
    /*position: fixed;*/
    z-index: 5;
    position: fixed;
    width: 100vw;
    height: calc(100% - 65px);
    background: #004d27;

    /*background: rgba(76, 175, 80, 0.3);*/
    opacity: 0.8;
    top: -100vh;
    text-align: center;
    transition: all 0.4s;
    flex-direction: column;
  }

  .menu_items li {
    margin: 30px 0 0 0;
    line-height: 30px;
  }

  .menu_items li:hover {
    background: none;
  }

  .menu_items li a {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
  }

  .menu_items li a:hover {
    color: #c8ff00;
  }

  .logo {
    font-size: 25px;
    padding-left: 35px;
  }
}

@media screen and (max-width: 858px) and (orientation: landscape) {
  .menu_items li {
    margin: 5px 0 0 0;
  }

  .menu_items {
    overflow: scroll;
    height: calc(100% - 65px);
  }
}

.menu_corp {
  /*background-color: #097948;*/
  background-color: #004d27;
  height: 20px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: right;
  border-bottom: 1px solid #fff;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.6);
}

.menu_items_corp {
  display: flex;
  list-style: none;
  margin-right: 30px;
}

.menu_items_corp li a {
  padding: 1px 13px;
  text-decoration: none;
  font-size: 12px;
  display: block;
  /*text-transform: uppercase;*/
  color: #fff;
}
</style>
