<template>
  <div>
    <NavbarMain />

    <!-- <div class="contenedor-trabaja mt-4">
      <h3 class="tittle-bln">Actualización de datos</h3>
      <form>
        <div class="p-fluid grid formgrid">
          <div class="field col-12 md:col-12">
            <p>
              Apreciado usuario te invitamos a mentaner tus datos actualizados.
              Conforme al Decreto 1377 de 2013, reglamentario de la Ley 1581 de
              2012 en materia de protección de datos personales, COMPAÑÍA
              LIBERTADOR S.A. solicita a todas las personas naturales
              registradas en nuestras bases de datos el cumplimiento de las
              obligaciones legales que permitan y autoricen la continuación del
              tratamiento de sus datos personales, en los términos previstos en
              la política de tratamiento de datos personales de COMPAÑÍA
              LIBERTADOR S.A.
            </p>
          </div>

          <div class="field col-12 md:col-12">
            <InputText
              placeholder="Nombre completo"
              v-model="hoja.nombrecompleto"
            />
          </div>

          <div class="field col-12 md:col-12">
            <InputText placeholder="Telefono" v-model="hoja.telefono" />
          </div>

          <div class="field col-12 md:col-12">
            <InputText placeholder="Correo" v-model="hoja.correo" />
          </div>

          <div class="field col-12 md:col-12">
            <input
              type="file"
              class="form-control-file"
              id="pqr_files"
              ref="pqr_files"
              @change="onFileChange"
              multiple
            />
          </div>

          <div class="check-acepto mb-3">
            <InputSwitch
              inputId="switch1"
              style="zoom: 70%"
              class="mr-2"
              v-model="hoja.acepto"
            />
            <label for="switch1"
              >Al activar esta casilla admito que he leído y aceptado la:
              <a
                href="https://www.berlinave.com/ProteccinDatosPersonales.pdf"
                target="_blank"
                >Política de protección de datos Personales de Cia Libertador SA
                *</a
              ></label
            >
          </div>

          <div class="field col-12 md:col-12">
            <Button
              icon="pi pi-check"
              autofocus
              label="Enviar"
              :disabled="hoja.botondisabled"
              @click="enviarHV"
            />
          </div>

          <div v-show="showError" class="field col-12 md:col-3">
            <InlineMessage severity="error">{{ messageWAR }}</InlineMessage>
          </div>
        </div>

        <div v-show="showProgress">
          <ProgressBar mode="indeterminate" style="height: 0.5em" />
        </div>
      </form>
    </div> -->

    <main>
      <div class="card-column card-form">
        <h4>Actualización de datos personales</h4>
        <div class="panel-registro">
          <div class="card-gray">
            <p style="text-align: justify">
              Apreciado usuario te invitamos a mentaner tus datos actualizados.
              Conforme al Decreto 1377 de 2013, reglamentario de la Ley 1581 de
              2012 en materia de protección de datos personales,<strong>
                COMPAÑÍA LIBERTADOR S.A.
              </strong>
              solicita a todas las personas naturales registradas en nuestras
              bases de datos el cumplimiento de las obligaciones legales que
              permitan y autoricen la continuación del tratamiento de sus datos
              personales, en los términos previstos en
              <strong
                >la política de tratamiento de datos personales de COMPAÑÍA
                LIBERTADOR S.A.</strong
              >
            </p>
          </div>
          <form>
            <div class="mb-4 row">
              <div class="form-group col-12 col-md-6">
                <Dropdown
                  v-model="cliente.tipoDocumento"
                  :options="tiposDocumentos"
                  optionLabel="name"
                  placeholder="Seleccione el tipo de documento..."
                  class="w-100"
                />
              </div>
              <div class="form-group col-12 col-md-6">
                <FloatLabel>
                  <InputText
                    class="w-100"
                    id="documento"
                    placeholder="Documento"
                    v-model="cliente.documento"
                    autocomplete="off"
                  />
                  <label for="documento">Documento</label>
                </FloatLabel>
              </div>
            </div>

            <div class="mb-4 row">
              <div class="form-group col-12 col-md-6">
                <FloatLabel class="margin-custom">
                  <InputText
                    class="w-100"
                    id="nombre"
                    placeholder="Nombres"
                    v-model="cliente.nombre"
                    autocomplete="off"
                  />
                  <label for="nombre">Nombres</label>
                </FloatLabel>
              </div>
              <div class="form-group col-12 col-md-6">
                <FloatLabel>
                  <InputText
                    class="w-100"
                    id="apellido"
                    placeholder="Apellidos"
                    v-model="cliente.apellido"
                    autocomplete="off"
                  />
                  <label for="apellido">Apellidos</label>
                </FloatLabel>
              </div>
            </div>

            <div class="mb-4 row">
              <div class="form-group col-12 col-md-6">
                <FloatLabel class="margin-custom">
                  <InputText
                    class="w-100"
                    id="correo"
                    placeholder="Correo"
                    v-model="cliente.correo"
                    autocomplete="off"
                  />
                  <label for="correo">Correo</label>
                </FloatLabel>
              </div>
              <div class="form-group col-12 col-md-6">
                <FloatLabel>
                  <InputText
                    class="w-100"
                    id="telefono"
                    placeholder="Telefono"
                    v-model="cliente.telefono"
                    autocomplete="off"
                  />
                  <label for="telefono">Telefono</label>
                </FloatLabel>
              </div>
            </div>

            <div class="mb-4 row">
              <div class="form-group col">
                <Calendar
                  placeholder="Fecha de nacimiento"
                  v-model="cliente.fechanacimiento"
                  class="w-100"
                  showIcon
                  :showOnFocus="false"
                  dateFormat="yy-mm-dd"
                />
              </div>
            </div>

            <div class="mb-4">
              <InputSwitch
                inputId="switch1"
                style="zoom: 70%"
                class="mr-2"
                v-model="cliente.terminos"
              />
              <label for="switch1"
                ><H1></H1>He leído y aceptado la:
                <a
                  href="https://www.berlinave.com/ProteccinDatosPersonales.pdf"
                  target="_blank"
                  >Política de protección de datos Personales de Cia Libertador
                  SA *</a
                ></label
              >
            </div>

            <div v-show="showError" class="mb-4 row">
              <InlineMessage :severity="typeSeverity">{{
                messageWAR
              }}</InlineMessage>
            </div>

            <div class="mb-4 row" v-show="showProgress">
              <ProgressBar mode="indeterminate" style="height: 0.5em" />
            </div>

            <div class="mb-4 row">
              <Button
                @click="actualizarDatos"
                label="Actualizar datos"
                severity="success"
                rounded
                :disabled="showProgress"
              />
            </div>
          </form>
        </div>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
//import InputText from "primevue/inputtext";
/*
  import Dropdown from "primevue/dropdown";
  import AutoComplete from "primevue/autocomplete";
  import Calendar from "primevue/calendar";
  import CascadeSelect from "primevue/cascadeselect";
  */
const $ = require("jquery");
window.$ = $;
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      cliente: {
        empresa: 300,
        tipoDocumento: null,
        documento: "",
        nombre: "",
        apellido: "",
        telefono: "",
        correo: "",
        fechanacimiento: "",
        fechaCompleta: "",
        terminos: false,
      },

      messageWAR: "",
      showError: false,
      typeSeverity: "success",

      bloquearCampos: false,
      showProgress: false,

      tiposDocumentos: [
        { name: "Cédula de Ciudadanía", code: 1 },
        { name: "Pasaporte", code: 2 },
        { name: "Cédula de Extranjería", code: 3 },
        { name: "Registro Civil", code: 4 },
        { name: "Tarjeta de Identidad", code: 5 },
      ],
    };
  },

  created: function () {
    //this.$utilman.fn1();
  },

  computed: {
    validarActualizacion() {
      var mensajeAlerta = "";

      if (this.cliente.tipoDocumento === null) {
        mensajeAlerta = "Debe seleccinar un tipo de documento!";
      } else if (
        this.cliente.documento === "" ||
        this.cliente.documento.length > 20
      ) {
        mensajeAlerta = "Debe digitar un N° de documento válido!";
      } else if (this.cliente.nombre === "" || this.cliente.nombre.length > 50) {
        mensajeAlerta = "Debe digitar un nombre válido!";
      } else if (this.cliente.apellido === ""  || this.cliente.apellido.length > 50) {
        mensajeAlerta = "Debe digitar un apellido válido!";
      } else if (
        this.cliente.correo === "" ||
        !this.$utilidad.validarCorreo(this.cliente.correo) ||
        this.cliente.correo.length > 100
      ) {
        mensajeAlerta = "Debe digitar un correo válido!";
      } else if (
        this.cliente.telefono === "" ||
        this.cliente.telefono.length != 10
      ) {
        mensajeAlerta = "Debe digitar un N° de teléfono celular válido!";
      }
      else if (this.cliente.fechanacimiento === "") {
        mensajeAlerta = "Debe digitar una fecha de nacimiento válida!";
      } else if (this.cliente.terminos === false) {
        mensajeAlerta =
          "Debe estar de acuerdo con la política de tratamiento de datos!";
      }

      return mensajeAlerta;
    },
  },

  methods: {
    actualizarDatos: async function () {
      try {
        var mensaje = this.validarActualizacion;

        if (mensaje === "") {
          let day = this.cliente.fechanacimiento.getDate();
          let month = this.cliente.fechanacimiento.getMonth() + 1;
          let year = this.cliente.fechanacimiento.getFullYear();
          this.fechaCompleta =
            year +
            "-" +
            (month < 10 ? "0" + month : month) +
            "-" +
            (day < 10 ? "0" + day : day);

          var parametros = {
            tipo_documento: this.cliente.tipoDocumento.code,
            nombres: this.cliente.nombre,
            apellidos: this.cliente.apellido,
            correo_electronico: this.cliente.correo,
            fecha_nacimiento: this.fechaCompleta,
            numero_documento: this.cliente.documento,
            telefono_celular: this.cliente.telefono,
            empresa_id: this.cliente.empresa,
          };

          this.showProgress = true;
          this.showError = false;

          let self = this;
          await this.axios
            .post(
              "https://clientes.berlinasdelfonce.com:8086/insertar-datos",
              parametros,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(function (response) {
              if (response.status === 200) {
                self.typeSeverity = "success";
                self.messageWAR =
                  "Los datos se han actualizado de manera exitosa!";
                self.showError = true;

                self.cleanFieldActualizarDatos();
              } else {
                self.typeSeverity = "error";
                self.messageWAR = "La respuesta del servidor NO fue exitosa!";
                self.showError = true;
                console.log(response.data);
              }
            })
            .catch((error) => {
              self.typeSeverity = "error";
              self.messageWAR =
                "Se presentó un error en la petición, vuelva a intentarlo";
              self.showError = true;
              console.log(error);
            })
            .finally(() => {
              this.showProgress = false;
            });
        } else {
          this.typeSeverity = "warn";
          this.messageWAR = mensaje;
          this.showError = true;
        }
      } catch (e) {
        console.log("Exception Javascript: " + e);
        this.typeSeverity = "warn";
        this.messageWAR = "Se ha presentado un error en el Frontend";
        this.showError = true;
      }
    },

    cleanFieldActualizarDatos() {
      this.cliente = {
        empresa: 300,
        tipoDocumento: null,
        documento: "",
        nombre: "",
        apellido: "",
        telefono: "",
        correo: "",
        fechanacimiento: "",
        fechaCompleta: "",
        terminos: false,
      };
    },
  },

  components: {
    NavbarMain,
    Footer,
  },
};
</script>

<style scoped>
/* .img-main {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center center;
}
.contenedor-trabaja {
  width: 70%;
  margin: auto;
} */

.check-acepto {
  display: flex;
  justify-content: center;
}

.console {
  border: 2px red solid;
}

/* .mensaje-habbeas-data {
  margin-bottom: 20px;
  font-size: 13px;
}

.titulo p {
  color: #004d27;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  margin-top: 10px;
}

.btn-berlinave {
  background-color: #004d27 !important;
  border-color: #004d27 !important;
  color: white !important;
} */

main {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  max-width: 95%;
  margin: 0.6rem auto;
}

.card-column {
  margin: 2rem auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-gray {
  padding: 20px;
  background-color: #f1f2f2;
  border-radius: 8px;
  /* box-shadow: -5px 3px 0px 3px rgba(0,153,68,1); */
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.card-form {
  padding: 1rem 2rem;
}

.nav-vertical {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: center;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  transition: 0.3;
}

.nav-vertical li:hover {
  background-color: #009944;
  color: #fff;
  font-weight: bold;
  transition: 0.2s;
  scale: 1.05;
  /* border: 2px solid #004d27; */
  /* border: 2px solid red; */
}

.nav-vertical li {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 3rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.child-first {
  width: 20%;
  height: fit-content;
}

.panel-registro {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
}

.form-check {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .panel-registro {
    grid-template-columns: 1fr;
    padding: 0.1rem;
  }

  .card-gray {
    font-size: 0.8rem;
  }

  .margin-custom {
    margin-bottom: 1.5rem;
  }
}
</style>
