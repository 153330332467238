<template>
  <!--
  <div class="slider">
    <div class="slide active">
      <img src="@/assets/amurallada.jpg" class="img-fluid" />
      <div class="info">
        <h2>Conoce nuestra promoción ida y vuelta</h2>
        <p>
          Viaja con nosotros y experimenta la mejor experiencia de viaje y el
          mejor confort para disfrutar de un vuaje placentero
        </p>

        <button
          type="button"
          class="btn btn-lg btn-danger"
          data-toggle="popover"
          title="Popover title"
          data-content="And here's some amazing content. It's very engaging. Right?"
        >
          Click aquí
        </button>
      </div>
    </div>
    <div class="slide">
      <img src="@/assets/cover-twitterfebrero.png" class="img-fluid" />
      <div class="info">
        <h2>Viaja con nosotros</h2>
        <p>
          Viaja con nosotros y experimenta la mejor experiencia de viaje y el
          mejor confort para disfrutar de un vuaje placentero
        </p>
      </div>
    </div>
    <div class="slide">
      <img src="@/assets/aracataca.png" class="img-fluid" />
    </div>
    <div class="navigation">
      <i class="fas fa-chevron-left prev-btn"></i>
      <i class="fas fa-chevron-right next-btn"></i>
    </div>

    <div class="navigation-visibility">
      <div class="slide-icon active"></div>
      <div class="slide-icon"></div>
      <div class="slide-icon"></div>
    </div>
  </div> -->

  <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li
        data-target="#carouselExampleCaptions"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          src="@/assets/vacaciones.jpg"
          class="d-block w-100"
          alt="..."
          data-interval="3000"
        />
        <div class="carousel-caption d-none d-md-block">
          <!--
          <div class="promo">
           <h4 class="textopromo">Pregunta por la promoción ida y regreso</h4>
            <router-link :to="{name:'IdaVuelta'}" class="btn btn-light btn-berlinave">Ver mas</router-link>
          </div>
          -->
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="@/assets/buses.jpg"
          class="d-block w-100"
          alt="..."
          data-interval="3000"
        />
                <div class="carousel-caption d-none d-md-block">
              <!--
          <div class="promo">
            <h4 class="textopromo">Pregunta por la promoción ida y regreso</h4>
            <router-link :to="{name:'IdaVuelta'}" class="btn btn-light btn-berlinave">Ver mas</router-link>
  
            <button
              type="button"
              class="btn btn-light btn-berlinave"
              data-toggle="modal"
              data-target="#modal-radicar">
              <span class="text">Ver promo</span>
            </button> 
          </div>
           -->
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleCaptions"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleCaptions"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
const $ = require("jquery");
window.$ = $;
export default {
  name: "Slider",

  data() {
    return {};
  },

  created: function() {
    $(".carousel").carousel();
  },

  methods: {
    verPromo: function() {
      alert("VER PROMO");
    },
  },
};
</script>

<style>
* {
  font-family: "Poppins", sans-serif;
}

.slider {
  /*z-index: 1;*/
  position: relative;
  background: #097948;
  width: 100%;
  min-height: 600px;
  /*
    width: 800px;
    min-height: 500px;
    margin: 10px;*/
  overflow: hidden;
  border-radius: 10px;
  /*margin-bottom: 20px;*/
}



.promo{
  text-align: left;
  margin-bottom: 40%;
}

.slider .slide {
  /*z-index: 1;*/
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: circle(0% at 0 50%);
}

.slider .slide.active {
  clip-path: circle(150% at 0 50%);
  transition: 2s;
}

.slider .slide img {
  /*z-index: 1;*/
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-inner .carousel-item .info {
  position: absolute;
  color: #004d27;
  background: rgba(255, 255, 255, 0.3);
  width: 75%;
  margin-top: 50px;
  margin-left: 50px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 25px rgb(1, 1, 1 / 5%);
}

.slider .slide .info {
  position: absolute;
  color: #004d27;
  background: rgba(255, 255, 255, 0.3);
  width: 75%;
  margin-top: 50px;
  margin-left: 50px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 25px rgb(1, 1, 1 / 5%);
}

.carousel-inner .carousel-item .info h2 {
  font-size: 2em;
  font-weight: 800;
}

.slider .slide .info h2 {
  font-size: 2em;
  font-weight: 800;
}

.carousel-inner .carousel-item .info p {
  font-size: 1em;
  font-weight: 400;
}

.slider .slide .info p {
  font-size: 1em;
  font-weight: 400;
}

.navigation {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slider:hover .navigation {
  opacity: 1;
}

.slide .carousel-item .carousel-inner .carousel-float {
  z-index: 999;
}

.prev-btn,
.next-btn {
  z-index: 2;
  font-size: 2em;
  color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  cursor: pointer;
}

.prev-btn {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.next-btn {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.navigation-visibility {
  z-index: 2;
  display: flex;
  justify-content: center;
}

.navigation-visibility .slide-icon {
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
  width: 10px;
  height: 10px;
  transform: translate(-50px);
  margin: 0 15px;
  border-radius: 2px;
  box-shadow: 0 5px 25px rgb(1, 1, 1 / 20%);
}

.navigation-visibility .slide-icon.active {
  background: #004d27;
}

@media (max-width: 900px) {
  .slider {
    width: 100%;
  }

  /*EDIT*/

  .slider .slide .info {
    position: relative;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 500px) {
  .slider .slide .info h2 {
    font-size: 1.8em;
    line-height: 40px;
  }

  .slider .slide .info p {
    font-size: 0.9em;
  }

  .slider {
    height: 50%;
  }

  /*EDIT*/
}
</style>
