<template>
  <div>
    <NavbarMain />

    <div class="contenedor">
      <div class="card-tittle">Descarga tu certificado de viaje en línea</div>

      <div class="card-subtittle">
        <div
          class="img-detail"
          :style="{
            'background-image': 'url(' + require(`@/assets/${imgEnter}`) + ')',
          }"
        >
          .
        </div>
        <p>
          Digite el número de documento y presione la tecla (ENTER), valide su
          identidad mediante correo o teléfono, ingrese el token recibido y
          presione la tecla (ENTER) para ver sus viajes.
        </p>
      </div>

      <div class="card-subtittle">
        <div
          class="img-detail"
          :style="{
            'background-image': 'url(' + require(`@/assets/${imgPDF}`) + ')',
          }"
        >
          .
        </div>
        <p>
          Haga clic en el ícono de PDF para generar tu certificado o en su
          defecto seleccione varios viajes y presione el botón: Generar
          certificado de los viajes seleccionados.
        </p>
      </div>

      <div class="fomulary">
        <div class="formulary-in">
          <input
            class="form-control"
            type="text"
            v-model="buscarcertificado.documento"
            :readonly="block_documento"
            id="view_certificado_documento"
            placeholder="Consultar por documento"
            @keyup.enter="buscarCertificadoXDocumento"
            maxlength="10"
          />

          <input
            class="form-control"
            type="text"
            :disabled="!showTxtToken"
            v-model="buscarcertificado.token"
            id="view_certificado_token"
            autocomplete="off"
            placeholder="Ingrese el Token recibido"
            @keyup.enter="consultarViajesXDocumento"
            maxlength="6"
            style="text-align: center"
          />
        </div>
      </div>

      <div class="loading-large" v-show="showProgressCertificados">
        <ProgressBar mode="indeterminate" style="height: 0.5em" />
      </div>

      <div class="boton-token" v-show="showButtonLarge">
        <button
          type="button"
          class="btn-berlinave"
          v-show="showButtonLarge"
          @click="generarMultiplesSeleccionados"
        >
        <i class="fa-regular fa-file-pdf"></i>
          Generar certificado
        </button>
      </div>

      <div class="table-responsive table-certify">
        <table class="table table-striped">
          <thead style="background-color: #004d27; color: white">
            <tr>
              <th scope="col">FECHA</th>
              <th scope="col">HORA</th>
              <th scope="col">TIQUETE</th>
              <th scope="col">ORIGEN</th>
              <th scope="col">DESTINO</th>
              <th scope="col">CERTIFICADO</th>
              <th scope="col">SELECCIONAR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tiquete in tiquetes" :key="tiquete.numero">
              <td>{{ tiquete.fecha }}</td>
              <td>{{ tiquete.hora }}</td>
              <td>{{ tiquete.numero }}</td>
              <td>{{ tiquete.origen }}</td>
              <td>{{ tiquete.destino }}</td>
              <td>
                <button
                  type="button"
                  v-on:click="generar(tiquete.numero)"
                  class="btn btn-Light"
                >
                  <font-awesome-icon
                    :icon="{ prefix: 'fa', iconName: 'file-pdf' }"
                  />
                </button>
              </td>
              <td>
                <Checkbox
                  :id="tiquete.numero"
                  name="tiquete"
                  :value="tiquete"
                  v-model="selectedTiquetes"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Dialog
      header="Header"
      :visible="showmodalvalidar"
      :modal="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '768px': '90vw', '575px': '100vw' }"
      @update:visible="ocultarDialogVerificacionIdentidad"
    >
      <template #header>
        <center>
          <h5>
            Seleccione una opción, recibirá un código para verificar tu
            identidad
          </h5>
        </center>
      </template>

      <p>
        Si no posee información de contacto registrada en nuestras bases de
        datos debe acercarse a una de nuestras taquillas para actualizar sus
        datos con su documento en físico y posteriormente puede intentar de
        nuevo el proceso de generación de certificados de viaje
      </p>

      <br />

      <form>
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body">
              <form>
                <div class="form-row">
                  <div class="mensaje-habbeas-data"></div>
                </div>

                <div
                  v-for="category of categories"
                  :key="category.key"
                  class="field-radiobutton"
                >
                  <RadioButton
                    :id="category.key"
                    name="category"
                    :value="category"
                    v-model="selectedCategory"
                  />
                  <label :for="category.key">{{ category.name }}</label>
                </div>

                <div v-show="showProgressGenerandoToken">
                  <ProgressBar mode="indeterminate" style="height: 0.5em" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </form>
      <template #footer>
        <ProgressSpinner
          class="mr-2"
          style="width: 40px; height: 40px"
          strokeWidth="8"
          fill="#FFFFFF"
          v-show="tokenfrm.wait"
          animationDuration=".5s"
        />

        <Button
          icon="pi pi-check"
          autofocus
          :label="tokenfrm.botonlabel"
          :disabled="tokenfrm.botondisabled"
          @click="getToken"
        />
      </template>
    </Dialog>

    <Footer />
  </div>
</template>

<script>
//import Dropdown from "primevue/dropdown";

//import InputText from "primevue/inputtext";
/*
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";
import Calendar from "primevue/calendar";
import CascadeSelect from "primevue/cascadeselect";
*/

const $ = require("jquery");
window.$ = $;
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      imgEnter: "enter.jpg",
      imgPDF: "pdf.png",
      showmodalvalidar: false,
      showProgressCertificados: false,
      showTxtToken: false,
      showButtonLarge: false,

      buscarcertificado: {
        documento: "",
        tiquete: "",
        token: "",
        correo: "",
      },

      tokenfrm: {
        wait: false,
        botonlabel: "Enviar Token",
        botondisabled: false,
      },

      token: "",
      correo: "",
      telefono: "",
      eleccion: "",
      tiquetes: [],
      city: null,
      categories: [],
      selectedCategory: null,
      resp: [],

      selectedTiquetes: [],
      block_documento: false,

      showProgressGenerandoToken: false,
    };
  },

  created: function () {},

  computed: {},

  methods: {
    ocultarDialogVerificacionIdentidad: function (value) {
      if (!value) {
        this.showmodalvalidar = false;
      }
    },

    buscarCertificadoXDocumento: async function () {
      var datosEnviar = {
        documento: this.buscarcertificado.documento,
        token: this.buscarcertificado.token,
      };
      this.showProgressCertificados = true;
      let vue = this;
      await this.axios
        .post(
          "model/certificadodeviaje.php?dato=getdatospasajero",
          JSON.stringify(datosEnviar)
        )
        .then(function (response) {
          vue.categories = response.data;
          vue.selectedCategory = vue.categories[0];
          vue.showTxtToken = false;
          vue.showButtonLarge = false;
          vue.showmodalvalidar = true;

          if (vue.categories[0] !== null) {
            vue.buscarcertificado.correo = vue.categories[0].llave;
          }
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressCertificados = false;
        });
    },

    consultarViajesXDocumento: async function () {
      var datosEnviar = {
        documento: this.buscarcertificado.documento,
        token: this.buscarcertificado.token,
      };
      this.showProgressCertificados = true;
      let vue = this;
      await this.axios
        .post(
          "model/certificadodeviaje.php?dato=gettiquetesxcedulaapi",
          JSON.stringify(datosEnviar)
        )
        .then(function (response) {
          if (response.data.includes("TOKEN INVÁLIDO")) {
            vue.$swal.fire("Advertencia!", response.data, "warning");
          } else {
            vue.tiquetes = response.data;
            vue.showTxtToken = false;
            vue.showButtonLarge = true;
            vue.block_documento = true;
          }
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressCertificados = false;
        });
    },

    buscarCertificadoXTiquete: async function () {
      var datosEnviar = {
        tiquete: this.buscarcertificado.tiquete,
      };
      this.showProgressCertificados = true;
      let vue = this;
      await this.axios
        .post(
          "model/certificadodeviaje.php?dato=gettiquetesxtiqueteapi",
          JSON.stringify(datosEnviar)
        )
        .then(function (response) {
          vue.tiquetes = response.data;
        })
        .catch((error) => {
          this.$utilidad.printConsole(error);
        })
        .then(function () {
          vue.showProgressCertificados = false;
        });
    },

    getToken: async function () {
      var datosEnviar = {
        key: this.selectedCategory.key,
        llave: this.selectedCategory.llave,
      };

      if (this.selectedCategory.key === "C") {
        if (!this.$utilidad.validarCorreo(this.selectedCategory.llave)) {
          this.$swal.fire(
            "Advertencia!",
            "Correo inválido para el envío del Token, debe actualizar su correo en una de nuestras taquillas",
            "warning"
          );
        } else {
          this.showProgressGenerandoToken = true;
          this.tokenfrm.wait = true;
          this.tokenfrm.botonlabel = "Enviando token...";
          this.tokenfrm.botondisabled = true;

          let vue = this;
          await this.axios
            .post(
              "model/certificadodeviaje.php?dato=gettoken",
              JSON.stringify(datosEnviar)
            )
            .then(function (response) {
              if (response.data.includes("SE HA ENVIADO TOKEN POR MAIL")) {
                vue.$swal.fire(
                  "Envío exitoso!",
                  "SE HA ENVIADO UN TOKEN AL CORREO SELECCIONADO ",
                  "success"
                );
                vue.showTxtToken = true;
                vue.showmodalvalidar = false;
              } else {
                this.$swal.fire(
                  "Advertencia!",
                  "Se ha producido un error al enviar el Token, confirme su correo electronico en nuestras taquillas",
                  "warning"
                );
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .then(function () {
              vue.showProgressGenerandoToken = false;
              vue.tokenfrm.wait = false;
              vue.tokenfrm.botonlabel = "Enviar token";
              vue.tokenfrm.botondisabled = false;
            });
        }
      } else {
        if (!this.$utilidad.validarCelular(this.selectedCategory.llave)) {
          this.$swal.fire(
            "Advertencia!",
            "Numero de teléfono inválido para el envío del Token, debe actualizar su numero de teléfono en una de nuestras taquillas",
            "warning"
          );
        } else {
          this.showProgressGenerandoToken = true;
          this.tokenfrm.wait = true;
          this.tokenfrm.botonlabel = "Enviando token...";
          this.tokenfrm.botondisabled = true;

          let vue = this;
          await this.axios
            .post(
              "model/certificadodeviaje.php?dato=gettoken",
              JSON.stringify(datosEnviar)
            )
            .then(function (response) {
              vue.showmodalvalidar = false;
              if (
                !response.data.includes(
                  "NO SE PUDO ENVIAR EL TOKEN POR MENSAJE DE TEXO"
                )
              ) {
                vue.$swal.fire(
                  "Envío exitoso!",
                  "SE HA ENVIADO UN MENSAJE DE TEXTO AL TELEFONO SELECCIONADO ",
                  "success"
                );
                vue.showTxtToken = true;
              } else {
                this.$swal.fire(
                  "Advertencia!",
                  "Se ha producido un error al enviar el Token, confirme su número de teléfono registrado en nuestras taquillas",
                  "warning"
                );
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .then(function () {
              vue.showProgressGenerandoToken = false;
              vue.tokenfrm.wait = false;
              vue.tokenfrm.botonlabel = "Enviar token";
              vue.tokenfrm.botondisabled = false;
            });
        }
      }
    },

    generarMultiplesSeleccionados: async function () {
      if (this.selectedTiquetes.length !== 0) {
        this.showProgressCertificados = true;

        var cadena = "";
        for (var tiquete in this.selectedTiquetes) {
          var cadenaLimpia = this.selectedTiquetes[tiquete].numero.trim();
          cadena = cadena + "_" + cadenaLimpia;
        }

        var datosEnviar = {
          tiquetes: cadena,
          cedula: this.buscarcertificado.documento,
          correo: this.buscarcertificado.correo,
        };

        if (!this.$utilidad.validarCorreo(this.buscarcertificado.correo)) {
          this.$swal.fire(
            "Advertencia!",
            "Correo inválido para el envío del certificado, debe actualizar su correo en una de nuestras oficinas",
            "warning"
          );
        } else {
          let vue = this;
          await this.axios
            .post(
              "https://www.berlinave.com/BERLINUXERP/gettiquetesseleccionadosemailcoli.php",
              JSON.stringify(datosEnviar)
            )
            .then(function (response) {
              if (response.data.includes("SE HA ENVIADO TOKEN POR MAIL")) {
                vue.$swal.fire(
                  "Envío exitoso!",
                  "Se ha enviado una copia del certificado al correo registrado, si no aparece en su bandeja de entrada por favor verificar en Spam ",
                  "success"
                );
              } else {
                vue.$swal.fire(
                  "Advertencia!",
                  "Se ha producido un error al enviar el certificado al correo registrado, confirme su correo electronico en nuestras oficinas",
                  "warning"
                );
              }
            })
            .catch((error) => {
              this.$utilidad.printConsole(error);
            })
            .then(function () {
              vue.showProgressCertificados = false;

              var link =
                "https://www.berlinave.com/BERLINUXERP/gettiquetesseleccionados.php?tiquetes=" +
                cadena +
                "&cedula=" +
                vue.buscarcertificado.documento;
              window.open(link, "_blank");
            });
        }
      } else {
        alert("No ha seleccionado viajes");
      }
    },

    generar: function (tiq) {
      var link = "https://www.berlinave.com/getcertificado.php?tiquete=" + tiq;
      window.open(link, "_blank");
    },
  },

  components: {
    NavbarMain,
    Footer,
  },
};
</script>

<style>
.contenedor {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.8rem;
  padding: 0.8rem;
  justify-content: center;
  /* margin: 1rem;
  row-gap: 1rem;
  column-gap: 1rem; */
}

.card-tittle {
  padding: 1rem;
  grid-column: span 2;

  border-radius: 10px;

  color: var(--green-blnv);
  /* font-family: 'Roboto', sans-serif; */
  font-family: "Comic Neue", cursive;
  font-weight: bold;
  font-size: 2rem;
}

.card-subtittle {
  padding: 1rem;
  grid-column: span 1;
  background: #f1f3f5;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.card-subtittle p {
  font-family: "Comic Neue", cursive;
  font-size: 1.3rem;
  text-align: justify;
}

.img-detail {
  width: 20rem;
  height: 11rem;
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
}

.fomulary {
  padding: 1rem;
  grid-column: span 2;
  background: #f1f3f5;
  border-radius: 10px;
}

.formulary-in {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.loading-large {
  grid-column: span 2;
}

.texto-token {
  grid-column: span 2;
  width: 60%;
  margin: auto;
}

.boton-token {
  grid-column: span 2;
  width: 60%;
  margin: auto;
}

.table-certify {
  /* font-size: 0.1rem; */
  max-width: 95vw;
  grid-column: span 2;
}

.btn-berlinave-2 {
  background-color: #004d27 !important;
  color: white !important;
}

.btn-berlinave {
  background: var(--green-blnv);
  /* color: #373b3f; */
  color: #fff;
  width: auto;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  padding: 0.8rem;
  border: none;
  border-radius: 50px;
  font-family: "Comic Neue", cursive;
  cursor: pointer;

  /* display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 3px; */
}

.formBottomlarge {
  margin-bottom: 10px;
}

.formtoken {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .contenedor {
    grid-template-columns: 1fr;
    /* margin: 1rem;
    row-gap: 1rem; */
    max-width: 95vw;
  }

  .card-tittle {
    grid-column: span 1;
    font-size: 1.2rem;
  }

  .card-subtittle {
    grid-column: span 1;
  }

  .card-subtittle > p {
    font-size: 0.9rem;
  }

  .card-subtittle > .img-detail {
    width: 10rem;
    height: 6rem;
  }

  .fomulary {
    grid-column: span 1;
  }

  .loading-large {
    grid-column: span 1;
  }

  .texto-token {
    grid-column: span 1;
  }

  .boton-token {
    grid-column: span 1;
  }

  .table-certify {
    /* font-size: 0.1rem; */
    /* max-width: 95vw; */
    grid-column: span 1;
  }

  .formulary-in {
    flex-direction: column;
  }

  /* .flotante-img {
    transform: translateY(100px) translateX(60px);
    font-size: 2rem;
  } */
}

@media screen and (max-width: 552px) {
  /* .flotante-img {
    transform: translateY(100px) translateX(150px);
    font-size: 2rem;
  } */

  .contenedor {
    grid-template-columns: 1fr;
    margin: 1rem;
    row-gap: 1rem;
  }

  .card-tittle {
    grid-column: span 1;
  }

  .card-subtittle {
    grid-column: span 1;
  }

  .fomulary {
    grid-column: span 1;
  }

  .btn-berlinave {
    font-size: 0.9rem !important;
  }
}
</style>
