<template>
  <div class="home">
    <NavbarMainSE />
    <NavbarMain />
    
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <FooterSE />
    <Footer /> 
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import NavbarMainSE from "@/components/NavbarMainSE.vue";
import FooterSE from "@/components/FooterSE.vue";
import NavbarMain from "@/components/NavbarMain.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'Home',
  components: {
    HelloWorld,
    NavbarMainSE,
    FooterSE,
    NavbarMain,
    Footer,
  }
}
</script>
